import { React , useEffect , useState } from "react";
import "./media.scss";

import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

import NewsApi from "../../components/NewsApi/NewsApi";

import news2_old from "../../assets/img/news/news2.png";
import news1 from "../../assets/img/news/coverage/1.png";
import news2 from "../../assets/img/news/coverage/2.png";
import news3 from "../../assets/img/news/coverage/3.png";
import news4 from "../../assets/img/news/coverage/4.png";
import news5 from "../../assets/img/news/coverage/5.png";
import news6 from "../../assets/img/news/coverage/6.png";
import news7 from "../../assets/img/news/coverage/7.png";
import news8 from "../../assets/img/news/coverage/8.png";
import news9 from "../../assets/img/news/coverage/9.png";
import news10 from "../../assets/img/news/coverage/10.png";
import Frame_6 from "../../assets/img/news/coverage/Frame_6.webp";
import Frame_5 from "../../assets/img/news/coverage/Frame_5.webp";
import Frame_8 from "../../assets/img/news/coverage/Frame_8.webp";
import Frame_7 from "../../assets/img/news/coverage/Frame_7.webp";

import Navbar from "../../components/Navbar/Navbar";
import media_banner from "../../assets/img/media-banner.jpg";
import config from "../../config";
import Spinner from "../../components/Spinner/Spinner";
import moment from "moment";

const Media = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const uploadUrl = "https://backend.ayanapower.com/storage/sustainablility_policy/";
  useEffect(() => {
    fetch(`${config.base_url}get-media-coverages`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);


  return (
    <div className="media">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"media"}
      >
        <img src={media_banner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Media</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Media
          </p>
        </div>
      </section>

      <section className="bg_grey">
        <div className="container">
          <div className="row">
            <div className="title text-center">
              <center>
                <h4>Latest news about us</h4>
              </center>
            </div>
          </div>
          <section>
            <NewsApi />
          </section>
        </div>
      </section>
      <section id="coverage">
        <div className="container">
          <div className="row">
            <div className="title text-center mb-2">
              <center>
                <h4>Coverage</h4>{" "}
              </center>
            </div>
          </div>

          <div className="row justify-content-center">

          {
            error ? (
              <div>Error: {error.message}</div>
            ):
             !isLoaded ? (
                <div>
                <Spinner />
                </div>
             ) : ( 
              items.data.map((data) => {
                  return <>
                           <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={`${uploadUrl}${data.image}`} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                      {data.title}
                      
                      </strong>{" "}
                      ({moment(data.posted_date).format("DD MMM, YYYY")}) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      {
                        data.url  ?  

                        <a
                        href={data.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                      : ''

                      }
                      
                    </p>
                  </div>
                </div>
              </div>
          </div>
                  </>
              })
             

             )
          }

          {/* <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={Frame_6} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                      Ayana Renewable Power signs Power Purchase Agreement with Hindalco Industries
                      
                      </strong>{" "}
                      (04 SEPTEMBER 2023) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.moneycontrol.com/news/business/ayana-renewable-power-signs-power-purchase-
                        agreement-with-hindalco-industries-11306611.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
          </div> */}


            {/* <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={Frame_8} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        Ayana Renewable Power receives Mahatma Award for ESG Excellence
                        
                        
                      </strong>{" "}
                      (06 OCTOBER 2023) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.manufacturingtodayindia.com/sectors/ayana-renewable-power-receives-mahatma-award-for-esg-excellence"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={Frame_7} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                      Hitachi Energy secures contract from Ayana Renewable for 300 MW connection
                      
                      
                      </strong>{" "}
                      (7 Sept 2023) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.cnbctv18.com/business/companies/hitachi-energy-secures-contract-from-ayana-
                        renewable-for-300-mw-connection-17741421.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={Frame_5} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                      Ayana Renewable Power inks pact with Hindalco Industries to develop 330 MW of solar and
                      wind capacity
                      
                      </strong>{" "}
                      (4 September  2023) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.financialexpress.com/business/industry-ayana-renewable-power-inks-pact-with-
                        hindalco-industries-to-develop-330-mw-of-solar-and-wind-capacity-3232373/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news6} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        Ayana Renewable bags 300 MW round-the-clock renewable
                        energy project
                      </strong>
                      (May 17, 2023) <br />
                      Ayana Renewable Power, a National Investment and Infrastructure Fund
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://energy.economictimes.indiatimes.com/news/renewable/ayana-renewable-bags-300-mw-round-the-clock-renewable-energy-project/100300552"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news7} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        With 300 MW Win In REMCL RTC Tender, Ayana Energy
                        Portfolio Set To Expand To 5 GW
                      </strong>
                      (May 17th, 2023) <br />
                      Bengaluru headquartered Ayana Renewable Power is set to expand it’s portfolio size to over 5 GW
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.saurenergy.com/solar-energy-news/with-300-mw-win-in-remcl-rtc-tender-ayana-energy-portfolio-set-to-expand-to-5-gw"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news8} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        Ayana Renewable Power Secures Landmark Tender to Deliver
                        300MW Round-the-Clock Renewable Energy to REMCL
                      </strong>
                      (May 7th, 2023) <br />
                      National Investment and Infrastructure Fund (NIIF) backed Ayana Renewable Power
                      Private Limited (Ayana) has bagged the tender
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://solarquarter.com/2023/05/17/ayana-renewable-power-secures-landmark-tender-to-deliver-300mw-round-the-clock-renewable-energy-to-remcl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news4} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        Unprecedented Polysilicon price volatility threatening
                        solar power capacity, invesments
                      </strong>
                      (May 3rd, 2023) <br />
                      The unprecedented volatility in polysilicon prices has set the domest
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://energy.economictimes.indiatimes.com/news/renewable/unprecedented-polysilicon-price-volatility-threatening-solar-power-capacityinvestments/98382153"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news9} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        Sprng, NTPC, Ayana, O2 Win Railway’s 1 GW Solar-Wind
                        Hybrid Auction
                      </strong>
                      (May 6th, 2023) <br />
                      Sprng Akshaya Urja (Sprng Energy), NTPC Renewable Energy,
                      Project Nine Renewable Power (Ayana Power)
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.mercomindia.com/sprng-ntpc-ayana-o2-1-gw-hybrid-auction"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news10} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>Industry Lookbook</strong> (Aug 2022) <br />
                      Top trends, challenges & concerns shaping the future of
                      finance
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.saurenergy.com/solar-energy-news/with-300-mw-win-in-remcl-rtc-tender-ayana-energy-portfolio-set-to-expand-to-5-gw"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news1} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        We Plan To Develop A 10 GW RE Portfolio By 2025
                      </strong>{" "}
                      (16 June 2022) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://solarquarter.com/2023/05/17/ayana-renewable-power-secures-landmark-tender-to-deliver-300mw-round-the-clock-renewable-energy-to-remcl/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news2} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>Interview with Shivanand Nimbargi</strong> (2021){" "}
                      <br />
                      We plan to be present in all spheres of renewable power
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://energy.economictimes.indiatimes.com/news/renewable/unprecedented-polysilicon-price-volatility-threatening-solar-power-capacityinvestments/98382153"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news3} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        Evaluating More M&As, InvITs To Expand Capacity
                      </strong>{" "}
                      (2020) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://www.mercomindia.com/sprng-ntpc-ayana-o2-1-gw-hybrid-auction"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-10 col-md-6 col-xl-6 mb-3">
              <div className="media_shadow_box">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2 text-center mb-2">
                    <img src={news5} alt="" className="img-fluid" />
                  </div>
                  <div className="col-12 col-md-12 col-lg-10">
                    <p>
                      <strong>
                        We plan to add 2 GW capacity every year : Shivanand
                        Nimbargi, MD and CEO
                      </strong>{" "}
                      (30 May 2020) <br />
                    </p>
                    <p className="blue_text news_know_more">
                      <a
                        href="https://energy.economictimes.indiatimes.com/news/renewable/we-plan-to-add-2-gw-capacity-every-year-shivanand-nimbargi-md-and-ceo-ayana-renewable-power/91884654"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Know More
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
           
          </div>
        </div>
      </section>
      {/* <section id="branding-assets">
        <div className="container">
          <div className="row">
            <div className="title text-center">
              <center>
                <h4>Branded Assets</h4>
              </center>
            </div>
          </div>
          <div className="row justify-content-center brandedAssetsContainer">
            <div className="col-md-3">
              <div className="media_shadow_box bg-white p-3">
              
                <a
                  className="blue_text pdf_link"
                  href="JavaScript:void(0);"
                  ondblclick="alert('Well done!')"
                  rel="noreferrer"
                >
                  <PictureAsPdfIcon /> Logo
                </a>
              </div>
            </div>
           
            <div className="col-md-3">
              <div className="media_shadow_box bg-white p-3">
                <a
                  className="blue_text pdf_link"
                  href="JavaScript:void(0);"
                  ondblclick="alert('Well done!')"
                  rel="noreferrer"
                >
                  <PictureAsPdfIcon /> Corporate Presentation
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="media_shadow_box bg-white p-3">
                <a
                  className="blue_text pdf_link"
                  href="JavaScript:void(0);"
                  ondblclick="alert('Well done!')"
                  rel="noreferrer"
                >
                  <PictureAsPdfIcon /> Company Profile
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="media_shadow_box bg-white p-3">
                <a
                  className="blue_text pdf_link"
                  href="JavaScript:void(0);"
                  ondblclick="alert('Well done!')"
                  rel="noreferrer"
                >
                  <PictureAsPdfIcon />
                  Project Disclosure
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </div>
  );
};

export default Media;
