import React, { useEffect, useState } from "react";
import parse from "html-react-parser";

import Spinner from "../Spinner/Spinner";
import { NavHashLink } from "react-router-hash-link";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1236 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const LeadershipCarosel = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const uploadUrl = "https://backend.ayanapower.com/storage/team-member/";
  useEffect(() => {
    fetch("https://backend.ayanapower.com/api/get-leaders")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <section>
        <>
          <Carousel autoPlay={true} infinite responsive={responsive}>
            {items.data.map((data) => (
              <div className="caroselBox shadow-sm p-4 ">
                <div className="image-wrapper mb-3">
                  <img
                    className="img-fluid"
                    src={`${uploadUrl}${data.image}`}
                    alt="person"
                  />
                </div>
                <div className="box-desc">
                  <h5>{data.name}</h5>
                </div>
                <div className="box-desc mb-2">
                  <p>{parse(data.designation)}</p>
                </div>
                <ul className="social">
                  <li className="d-flex justify-content-center">
                    <NavHashLink
                      className="navlink"
                      to={`/leadership/${data.slug}#`}
                    >
                      <button className="buttonStyle "> Know More</button>
                    </NavHashLink>
                    {/* <a
                      href={data.linkedin_url}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin"></i>
                    </a> */}
                  </li>
                </ul>
              </div>
            ))}
          </Carousel>
        </>
      </section>
    );
  }
};

export default LeadershipCarosel;
