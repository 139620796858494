import "./offeringstab.scss";
import utility_scale_wind from "../../assets/img/utility_scale_wind.png";
import utility_scale_solar from "../../assets/img/utility_scale_solar.png";
import utility_scale_wind_solar from "../../assets/img/utility_scale_wind_solar.png";
import round_the_clock_green_power from "../../assets/img/round_the_clock_green_power.png";
import green_h2 from "../../assets/img/green_h2.png";
import ProjectsTable1 from "../ProjectsTable/ProjectsTable1";
import ProjectsTable2 from "../ProjectsTable/ProjectsTable2";
import ProjectsTable3 from "../ProjectsTable/ProjectsTable3";
import ProjectsTable4 from "../ProjectsTable/ProjectsTable4";
import ProjectsTable5 from "../ProjectsTable/ProjectsTable5";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const OfferingsTab = () => {
  return (
    <>
      <div className="container offerings_tab_block" id={"offering"}>
        <Tabs
          defaultActiveKey="utility_scale_solar"
          id="fill-tab-example"
          className="mb-3"
          fill
        >
          <Tab eventKey="utility_scale_solar" title="Utility-Scale Solar">
            <div className="row offerings_tab_box_shadow">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4">
                <img src={utility_scale_solar} className="w-100 h-100" alt="" />
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8">
                <div className="offering_tab_right">
                  <h5 className="blue_text">Utility-Scale Solar</h5>
                  <p>
                    We develop utility-scale solar projects which are
                    cost-efficient. Our team of experts carefully evaluates each
                    project through risk analysis and sustainability
                    considerations before proceeding with development. We are
                    committed to staying at the forefront of solar technologies,
                    consistently searching for innovative solutions to maximise
                    energy generation and reduce carbon emissions.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <ProjectsTable1 />
              </div>
            </div>
          </Tab>
          <Tab eventKey="utility_scale_wind" title="Utility-Scale Wind">
            <div className="row offerings_tab_box_shadow">
              <div className="col-12 col-sm-12 col-md-4 col-xl-4">
                <img src={utility_scale_wind} className="w-100 h-100" alt="" />
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8">
                <div className="offering_tab_right">
                  <h5 className="blue_text">Utility-Scale Wind</h5>
                  <p>
                    We develop large-scale grid-connected wind projects and
                    supply electricity to various central, state, and other
                    government-backed agencies. Our team is dedicated to finding
                    innovative solutions that deliver clean energy at low
                    prices, aiding in the transition to sustainability. Through
                    collaboration with our clients and utilising cutting-edge
                    technology, we are able to provide reliable, cost-effective
                    wind power.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <ProjectsTable2 />
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="utility_scale_hybrid"
            title="Utility-Scale Wind-Solar Hybrid"
          >
            <div className="row offerings_tab_box_shadow">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4">
                <img
                  src={utility_scale_wind_solar}
                  className="w-100 h-100"
                  alt=""
                />
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8">
                <div className="offering_tab_right">
                  <h5 className="blue_text">Utility-Scale-Wind-Solar Hybrid</h5>
                  <p>
                    Our unique utility-scale grid-connected hybrid projects
                    harness the strengths of both wind and solar sources to
                    provide a reliable and stable source of power to our
                    consumers. By combining wind and solar energy, we are able
                    to ensure a steady supply throughout the day as well as
                    during fluctuating weather conditions. Our goal is to
                    support the clean energy transition while also meeting the
                    needs of our clients in terms of reliable and affordable
                    power.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <ProjectsTable3 />
              </div>
            </div>
          </Tab>
          <Tab eventKey="round_the_clock" title="Round-the-Clock Green Power">
            <div className="row offerings_tab_box_shadow">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4">
                <img
                  src={round_the_clock_green_power}
                  className="w-100 h-100"
                  alt=""
                />
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8">
                <div className="offering_tab_right">
                  <h5 className="blue_text"> Round-the-Clock Green Power</h5>
                  <p>
                    We offer round-the-clock green power to our customers
                    through the integration of battery storage technology. This
                    helps to ensure a steady, sustainable source of renewable
                    energy for our customers at all times. We are committed to
                    finding innovative solutions for a cleaner, greener future.
                    Our partnership with Greenko Group to store 6-gigawatt hours
                    (GWh) of power in Hydropump storage plants at Pinnapuram in
                    Andhra Pradesh will provide Round-the-Clock green energy to
                    electricity distribution companies and industries.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <ProjectsTable5 />
              </div>
            </div>
          </Tab>
          <Tab eventKey="green_hydrogen" title="Green Hydrogen">
            <div className="row offerings_tab_box_shadow">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4">
                <img src={green_h2} className="w-100 h-100" alt="" />
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-xl-8">
                <div className="offering_tab_right">
                  <h5 className="blue_text sample">Green Hydrogen</h5>
                  <p>
                    The addition of green hydrogen plants to our renewable
                    energy portfolio helps us achieve net zero emissions. As a
                    clean and efficient energy source, green hydrogen plants
                    produce hydrogen through electrolysis holding great
                    potential. We believe that investing in sustainable
                    solutions will benefit both the economy and the environment.
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <ProjectsTable4 />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </>
  );
};
export default OfferingsTab;
