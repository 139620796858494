import Axios from "./index";

const getAllBlogs = async () => {
  try {
    const response = await Axios.get("/blogs/get_all");
    return response?.data;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw the error to be caught by the caller
  }
};

const postContactDetails = async (payload) => {
  try {
    const response = await Axios.post(`/contact`, payload);
    return response?.data;
  } catch (error) {
    console.error("POST error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

const uploadImage = async (payload) => {
  try {
    const response = await Axios.post(`/upload`, payload);
    return response?.data;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

const postVendorRegistrationDetails = async (payload) => {
  try {
    const response = await Axios.post(`/vendor-registrations`, payload);
    return response?.data;
  } catch (error) {
    console.error("GET error:", error);
    throw error?.response?.data?.message; // Throw th{e error to be caught by the caller
  }
};

export {
  getAllBlogs,
  postContactDetails,
  uploadImage,
  postVendorRegistrationDetails,
};
