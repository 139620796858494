import { useState } from "react";
import { uploadImage } from "../network/users";

const useImageUpload = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [error, setError] = useState(null);

  const handleImageChange = (event) => {
    return new Promise((resolve, reject) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async () => {
          const base64String = reader.result.split(",")[1];
          const fileType = file.type.split("/")[1]; // Extract file extension
          let imageSrc;
          if (fileType === "svg+xml") {
            imageSrc = `data:image/svg+xml;base64,${base64String}`;
          } else {
            imageSrc = `data:image/${fileType};base64,${base64String}`;
          }
          let obj = {
            imageData: imageSrc,
          };
          try {
            const resp = await uploadImage(obj);
            if (resp?.message === "Image saved successfully") {
              setImageUrl(resp.imageUrl);
              resolve(resp.imageUrl);
            } else {
              reject("Image upload failed");
            }
          } catch (error) {
            setError(error);
            alert(error);
            reject(error);
          }
        };
        reader.readAsDataURL(file);
      } else {
        reject("No file selected");
      }
    });
  };

  return { imageUrl, error, handleImageChange };
};

export default useImageUpload;
