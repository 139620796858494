import React from "react";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import "./partners.scss";
import partner1 from "../../assets/img/partner1.png";
import partner2 from "../../assets/img/partner2.png";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
// import Navigation from "../../components/Naigation/Navigation";
import Navbar from "../../components/Navbar/Navbar";
import partners_banner from "../../assets/img/partnership-banner.png";
import pdf1 from "../../assets/pdf/ayana_greenko_joint_press_release_v2_clean_07022022.pdf";
import pdf2 from "../../assets/pdf/ayana_renewable_power_and_greenstat_hydrogen_enter_into_an_mou_to_support_india_hydrogen_mission_06092021.pdf";

const Partners = () => {
  return (
    <div className="">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"partners"}
      >
        <img src={partners_banner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Partners</h4>
          <p className="breadcrumb_link">
            {" "}
            <Link to="/"> Home</Link> | Partners
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div>
            <p className="text-center">
              We value our partners. We believe collaboration is an integral
              part of accelerating sustainable development.
            </p>
            <p className="text-center">
              For more details regarding partnership, send an email to
            </p>
            <p className="text-center">
              <a
                href="partnership@ayanapower.com"
                className="text-dark fw-bold"
              >
                partnership@ayanapower.com
              </a>
            </p>
          </div>
        </div>
      </section>

      <section className="bg_grey">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <div className="partner_shadow_box bg-white">
                <img src={partner1} className="img-fluid" alt="" />
                <div className="row mt-3 p-4">
                  <h6 className="partnerCardText">
                    Greenstat Hydrogen India- Green hydrogen project
                  </h6>
                  <a
                    className="knowMoreLink"
                    href="https://greenstat-india.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    To Know More Visit
                    <NorthEastIcon className=" ml-2" />
                  </a>
                  <a
                    className="knowMoreLink mt-2"
                    href={pdf2}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Press Release
                    <NorthEastIcon className=" ml-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="partner_shadow_box bg-white">
                <img src={partner2} className="img-fluid" alt="" />
                <div className="row mt-3 p-4">
                  <h6 className="partnerCardText">
                    Greenko Group- Round -the- Clock power supply project
                  </h6>
                  <a
                    className="knowMoreLink"
                    href="https://greenkogroup.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    To Know More Visit
                    <NorthEastIcon className="ml-2" />
                  </a>
                  <a
                    className="knowMoreLink mt-2"
                    href={pdf1}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Press Release
                    <NorthEastIcon className=" ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Partners;
