import React from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import Footer from "../../components/Footer/Footer";
import Vendor_Registration_Img from "../../assets/img/suppliers/vendor-registration.png";
import "./suppliers.scss";
import { Link } from "react-router-dom";

import CodeofConductforSuppliers from "../../assets/pdf/CodeofConductforSuppliers.pdf";
import Annexure_VII_Supply_Service_QHSE_Requirements from "../../assets/pdf/Suppliers and Contra/Annexure_VII_Supply_Service_QHSE_Requirements.pdf";
import CONTRACTOR_QHSE_EVALUATION from "../../assets/pdf/Suppliers and Contra/CONTRACTOR_QHSE_EVALUATION.pdf";
import Supplier_Selection_Criteria from "../../assets/pdf/Suppliers and Contra/Supplier_Selection_Criteria.pdf";
import { NavHashLink } from "react-router-hash-link";
import Navbar from "../../components/Navbar/Navbar";
import suppliers_banner from "../../assets/img/suppliers/suppliers_banner.jpg";

const Suppliers = () => {
  return (
    <div className="supplier">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"suppliers"}
      >
        <img src={suppliers_banner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Suppliers &amp; Contractors</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Suppliers &amp; Contractors
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div>
            <p className="mb-3">
              Supply chain management is an integral part of our business
              process, playing a crucial role in the entire production flow.
              With our suppliers, we strive to achieve our goal of attaining a
              clean and green future with innovation across the projects. Ayana
              Renewable Power’s code of conduct is designed to maintain
              transparency and integrity in business transactions. This supplier
              code of conduct sets forth the requirements that Ayana asks its
              suppliers to respect and adhere to when conducting business with
              or on behalf of Ayana.
            </p>
          </div>
          <div className="custom_pdf" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <a
            className="pdf_link"
            href={CodeofConductforSuppliers}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="d-flex "
              style={{ alignItems: "", justifyContent: "" }}
            >
              <div className="pdf_btn_logo bg_blue">
                <PictureAsPdfIcon />
              </div>
              <div className="pdf_btn_text">
                <p>View Code of Conduct</p>
              </div>
            </div>
          </a>
          
                    {/* 2nd */}
          <a
            className="pdf_link"
            href={Annexure_VII_Supply_Service_QHSE_Requirements}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="d-flex "
              style={{ alignItems: "", justifyContent: "" }}
            >
              <div className="pdf_btn_logo bg_blue">
                <PictureAsPdfIcon />
              </div>
              <div className="pdf_btn_text">
                <p>QHSE Requirements for Supply and
                    Service</p>
              </div>
            </div>
          </a>
                        {/* 3rd */}
          <a
            className="pdf_link"
            href={CONTRACTOR_QHSE_EVALUATION}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="d-flex "
              style={{ alignItems: "", justifyContent: "" }}
            >
              <div className="pdf_btn_logo bg_blue">
                <PictureAsPdfIcon />
              </div>
              <div className="pdf_btn_text">
                <p>Contractor QHSE Evaluation Form</p>
              </div>
            </div>
          </a>

              {/* 4th */}
              <a
            className="pdf_link"
            href={Supplier_Selection_Criteria}
            target="_blank"
            rel="noreferrer"
          >
            <div
              className="d-flex "
              style={{ alignItems: "", justifyContent: "" }}
            >
              <div className="pdf_btn_logo bg_blue">
                <PictureAsPdfIcon />
              </div>
              <div className="pdf_btn_text">
                <p>Supplier Selection Criteria</p>
              </div>
            </div>
          </a>
          </div>

          <div className="row">
            <div className="col-md-7"></div>
          </div>
        </div>
      </section>

      <section className="bg_grey">
        <div className="container">
          <div className="row introductionContainer">
            <div className="col-md-12">
              <div className="title pt-4 text-center">
                <h4 className="m-auto">Supplier Registration</h4>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 pt-4">
              <img className="img-fluid" src={Vendor_Registration_Img} alt="" />
            </div>

            <div className="col-md-6">
              <p>To join our network of suppliers, register here.</p>

              <div className="row mt-3">
                <div className="col-md-12">
                  <NavHashLink to="/vendor-form#">
                    <button className="btn btn_blue" style={{ width: "60%" }}>
                      Click to Register
                    </button>
                  </NavHashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Suppliers;
