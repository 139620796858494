import React from "react";
import "./csr-initiative.scss";
import csr1 from "../../assets/img/csr/csr1.png";
import csr2 from "../../assets/img/csr/csr2.jpeg";
import csr4 from "../../assets/img/csr/covid-awareness.jpg";
import csr5 from "../../assets/img/csr/csr5.png";
import csr6 from "../../assets/img/csr/csr6.jpg";
import csr7 from "../../assets/img/csr/csr7.jpg";
import csr8 from "../../assets/img/csr/micro-grid-jharkhand.jpg";
import csr_banner from "../../assets/img/csr-banner.jpg";

import icon1 from "../../assets/img/csr/icon1.svg";
import icon2 from "../../assets/img/csr/icon2.svg";
import icon3 from "../../assets/img/csr/icon3.svg";
import icon4 from "../../assets/img/csr/icon4.svg";
import icon5 from "../../assets/img/csr/icon5.svg";
import icon6 from "../../assets/img/csr/icon6.svg";
import icon7 from "../../assets/img/csr/icon7.svg";
import icon8 from "../../assets/img/csr/icon8.svg";
import icon9 from "../../assets/img/csr/icon9.svg";
import icon10 from "../../assets/img/csr/icon10.svg";
import icon11 from "../../assets/img/csr/icon11.svg";
import icon12 from "../../assets/img/csr/icon12.svg";
import icon13 from "../../assets/img/csr/icon13.svg";
import icon14 from "../../assets/img/csr/icon14.svg";
import icon15 from "../../assets/img/csr/icon15.svg";
import icon16 from "../../assets/img/csr/icon16.svg";
import icon17 from "../../assets/img/csr/icon17.svg";
import icon18 from "../../assets/img/csr/icon18.svg";
import icon19 from "../../assets/img/csr/icon19.svg";
import icon20 from "../../assets/img/csr/icon20.svg";
import icon21 from "../../assets/img/csr/icon21.svg";

import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";

const CsrInitiative = () => {
  return (
    <div className="csr_initiative">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"csr"}
      >
        <img src={csr_banner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Community Development</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | CSR Initiatives
          </p>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row align-items-center p-2">
            <div className=" monls">
              <h6 className="text_blue text-center">
                We are embarking on an initiative today to ensure a brighter
                tomorrow
              </h6>
              <p className="paraCsr text-center">
                A key objective of Ayana’s strategy is to provide skills,
                employment, and entrepreneurship opportunities to local
                communities, with a special focus on women, youth, and
                marginalised groups.
              </p>
            </div>
          </div>
          <div className="row  bg_grey px-2 align-items-center">
            <div className="col-md-12 col-lg-8 py-3">
              <h6 className="text_blue font_20 mt-4">
                Skill development program, Bikaner, Rajasthan
              </h6>
              <p className="paraCsr">
                The Skill Development Project in Bikaner was a significant
                initiative taken during the COVID-19 lockdown period. The
                project aimed to skill 180 youth in the solar sector, providing
                them with opportunities to develop relevant job skills. The
                project had a tremendous impact, with 135 of the trained youth
                successfully finding employment, thereby making a significant
                contribution to the regional economy. It is essential to note
                that the project was implemented during a challenging time when
                COVID-19 had disrupted the normal way of life. In this regard,
                the project was a remarkable success, providing a glimmer of
                hope for the youth during the period of turmoil. The funding for
                the project was provided by BII, (British International
                Investment), who is our significant shareholder and the project
                cost was covered by them. This support was crucial in ensuring
                the success of the project and the positive impact it had on the
                lives of the youth who received the training. The impact of the
                skill development project is far-reaching, with a ripple effect
                on the lives of family members of those who entered employment
                due to it.
              </p>
            </div>
            <div className="col-md-12 col-lg-4">
              <img src={csr2} className="w-100" alt="" />
            </div>
            <div className="col-md-12">
              <div className="mt-3 py-4 d-flex flex-wrap justify-content-between gap-3">
                <div className="d-flex align-items-center gap-3">
                  <img src={icon1} alt="" />
                  <div>
                    <p className="mb-0 projectPara">Solar Project</p>
                    <p className="projectdec">300 MW </p>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-3">
                  <img src={icon2} alt="" />
                  <div>
                    <p className="mb-0 projectPara">Upskilled</p>
                    <p className="projectdec">180 youth </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img src={icon3} alt="" />
                  <div>
                    <p className="mb-0 projectPara">
                      Enabled in securing placements
                    </p>
                    <p className="projectdec">135 </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img src={icon4} alt="" />
                  <div>
                    <p className="mb-0 projectPara">Placement (min)</p>
                    <p className="projectdec">70% (approx) </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row px-2 align-items-center pt-2">
            <div className="col-md-12 col-lg-4 order-md-2 order-lg-1">
              <img src={csr5} className="w-100" alt="" />
            </div>
            <div className="col-md-12 col-lg-8 py-3 order-lg-2">
              <h6 className="text_blue font_20 mt-4">
                Second phase of skill development program, Bikaner, Rajasthan
              </h6>
              <p className="paraCsr">
                Ayana Solar Project in Bikaner, Rajasthan has initiated the
                second phase of its skill development program to train and
                upskill 200 youth in the solar sector, with a target of placing
                a minimum of 70% of those who pass the assessment. The program
                includes training in Solar PV Installer (Suryamitra),
                personality development, and basic safety, in compliance with
                the National Green Job Sector Council. As of December 2022, a
                total of 135 youth have enrolled in the program, with four
                batches finalised for the training. Two batches are from the
                catchment village: one is from Sarvodaya ITI College, and the
                other is from Bikaner ITI College.
              </p>
            </div>
            <div className="col-md-12 py-4 order-md-3">
              <div className="mt-2 d-flex flex-wrap justify-content-between gap-3">
                <div className="d-flex align-items-center gap-3">
                  <img src={icon5} alt="" />
                  <div>
                    <p className="mb-0 projectPara">Target upskilling</p>
                    <p className="projectdec">200 Youth </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img src={icon6} alt="" />
                  <div>
                    <p className="mb-0 projectPara">Youth enrolled</p>
                    <p className="projectdec">200 </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img src={icon7} alt="" />
                  <div>
                    <p className="mb-0 projectPara">Target placement</p>
                    <p className="projectdec">70% </p>
                  </div>
                </div>
                <div className="d-flex align-items-center gap-3">
                  <img src={icon7} alt="" />
                  <div>
                    <p className="mb-0 projectPara">
                      Offer letter received by candidates
                    </p>
                    <p className="projectdec">
                       <span>Total: 140</span> | <span>Women: 20</span> 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row  bg_grey px-2  my-4">
            <div className="col-md-12 col-lg-8">
              <h6 className="text_blue font_20 mt-4">
                Provided supportive healthcare services during the pandemic at
                Bikaner, Rajasthan
              </h6>
              <p className="paraCsr">
                During the COVID-19 lockdown, a significant donation was made to
                the local communities in Bikaner. An ambulance was donated to
                serve the communities and help those in need. The donation was a
                gesture of support and assistance during challenging times, with
                the ambulance playing a crucial role in saving lives. The
                ambulance was made available to various communities, providing
                access to medical facilities for those who would have otherwise
                struggled to get help. The donation of the ambulance in Bikaner
                during the COVID-19 lockdown depicts how at Ayana, we take our
                role as a responsible citizen seriously. We believe that access
                to quality healthcare is a fundamental human right.
              </p>
            </div>
            <div className="col-md-12 col-lg-4">
              <img src={csr6} className="w-100" alt="" />
            </div>
          </div>

          <div className="row px-2">
            <div className="col-md-13 col-lg-4 order-2 order-lg-1">
              <img src={csr4} className="img-fluid csImg5" alt="" />
            </div>
            <div className="col-md-12 col-lg-8 py-3 order-1">
              <h6 className="text_blue font_20 mt-4">
                COVID awareness program
              </h6>
              <p className=" paraCsr pb-2">
                During the COVID-19 pandemic, Ayana launched a comprehensive
                campaign to raise awareness about COVID-19 prevention measures.
                The campaign was carried out over various social media
                platforms, including Facebook, TikTok, YouTube, and LinkedIn,
                with a combined reach of 41 million impressions. Ayana focused
                on designing and developing content, E-Posters, videos, and
                social media promotion to ensure that the message reached a
                broad audience. The campaign perfectly demonstrated Ayana's
                commitment to its social responsibility and its dedication to
                making a positive impact on the communities it serves. The
                comprehensive approach of the campaign, utilising various social
                media platforms, helped to ensure that the message of COVID-19
                prevention measures was disseminated widely, reaching people
                across different demographics and age groups, and making a
                positive impact on the overall public health situation.
              </p>
              <a
                className="green-text fw-bolder fs-6"
                href="https://www.youtube.com/embed/ih-hfprkXE0"
                target="_blank"
              >
                Youtube link for COVID Awareness Program
              </a>
              <div className="row mt-2 g-3 py-4">
                <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                  <div className="d-flex align-items-start gap-3">
                    <img src={icon8} alt="" />
                    <div>
                      <p className="mb-0 projectPara">
                        Social media impressions
                      </p>
                      <p className="projectdec">41 Million </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                  <div className="d-flex align-items-start gap-3">
                    <img src={icon9} alt="" />
                    <div>
                      <p className="mb-0 projectPara">Social media</p>
                      <p className="projectdec">
                        Facebook, YouTube, TikTok and LinkedIn.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-4">
                  <div className="d-flex align-items-start gap-3">
                    <img src={icon10} alt="" />
                    <div>
                      <p className="mb-0 projectPara">Minimum engagement</p>
                      <p className="projectdec">20,000 people</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <button className="btn btn_blue">Read More</button> */}
            </div>
          </div>
          <div className="row  bg_grey px-2">
            <div className="col-md-12 col-lg-8 py-3">
              <h6 className="text_blue font_20 mt-4">
                Skill development program, Anantapur, Andhra Pradesh
              </h6>
              <p className="paraCsr">
                The Anantapur skill development project, implemented as a pilot
                project along with Ayana's first solar green field project, was
                a remarkable success. The project aimed to skill 200 youth,
                including 84 women, in solar-specific job skills, financial
                literacy, and other social aspects. The project was primarily
                funded by DFID (Govt of UK) at 90% and BII at 10%. The trained
                youths showed remarkable progress, with 74 of them, including 4
                women having found jobs in various companies. This outcome was a
                clear indication of the success of the project and the impact it
                had on the lives of these young people. The Anantapur skill
                development project is an excellent example of how the skill
                development program can create job opportunities and empower
                young people especially women, to lead better lives. The
                outcomes of the skill development project are extensive,
                creating a chain reaction that touches the lives of many. When
                one person receives training and development, it can have a
                positive impact on others, especially their family members, and
                ultimately lead to the advancement and prosperity of the entire
                nation.
              </p>
              <div className="row g-4 mt-2">
                <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon11} alt="" />
                    <div>
                      <p className="mb-0 projectPara">Target upskilling</p>
                      <p className="projectdec">200 Youth </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon12} alt="" />
                    <div>
                      <p className="mb-0 projectPara">
                        No. of students enrolled
                      </p>
                      <p className="projectdec">183</p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon13} alt="" />
                    <div>
                      <p className="mb-0 projectPara">Students qualified</p>
                      <p className="projectdec">168</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon14} alt="" />
                    <div>
                      <p className="mb-0 projectPara">Students placed</p>
                      <p className="projectdec">74 </p>
                    </div>
                  </div>
                </div> */}
                <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon15} alt="" />
                    <div>
                      <p className="mb-0 projectPara">No. of women enrolled</p>
                      <p className="projectdec">84</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon16} alt="" />
                    <div>
                      <p className="mb-0 projectPara">
                        Enabled in securing placements
                      </p>
                      <p className="projectdec">
                        <span>Total: 75</span> | <span>Women: 04</span> 
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-xl-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon17} alt="" />
                    <div>
                      <p className="mb-0 projectPara">
                        Jobs in solar power park
                      </p>
                      <p className="projectdec">70 </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-4">
              <img src={csr7} className="img-fluid w-100" alt="" />
            </div>
          </div>
          <div className="row px-2">
            <div className="col-md-12 col-lg-4 order-2">
              <img src={csr8} className="img-fluid csImg8" alt="" />
            </div>
            <div className="col-md-12 col-lg-8 py-3 order-lg-2">
              <h6 className="text_blue font_20 mt-4">
                Microgrid in Chatra, Jharkhand
              </h6>
              <p className="paraCsr">
                The Microgrid with Lithium Ion Battery project in Jharkhand was
                an excellent example of collaboration between multiple entities
                to address an important issue. The project was commissioned
                jointly by USAID, Panasonic, and Sologix, in partnership with
                Ayana. The project aimed to provide access to electricity to
                underserved communities in remote locations of Chatra District,
                Jharkhand, where households had no access to power. The
                microgrid, powered by a lithium-ion battery, was instrumental in
                providing electricity to 25 households in the area, enabling
                them to improve their quality of life. This project was not just
                about providing access to electricity but was also a
                demonstration of the potential of innovative solutions to
                address critical issues. The collaboration between multiple
                organisations and entities was crucial to the success of the
                project, and it served as a model for future projects that could
                have a positive impact on underserved communities.
              </p>
              <div className="row mt-2 g-3 py-4">
                <div className="col-md-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon18} alt="" />
                    <div>
                      <p className="mb-0 projectPara">Proposed plant size</p>
                      <p className="projectdec">6.50 kW </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon19} alt="" />
                    <div>
                      <p className="mb-0 projectPara">
                        No. of households electrified
                      </p>
                      <p className="projectdec">25 </p>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon20} alt="" />
                    <div>
                      <p className="mb-0 projectPara">
                        Load sanctioned to each household
                      </p>
                      <p className="projectdec">200 Youth </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex align-items-center gap-3">
                    <img src={icon21} alt="" />
                    <div>
                      <p className="mb-0 projectPara">Tentative project Life</p>
                      <p className="projectdec">25 Years </p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default CsrInitiative;
