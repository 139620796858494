import React, { useEffect, useState } from "react";
import "./leadership.scss";
import { NavHashLink } from "react-router-hash-link";
import Spinner from "../Spinner/Spinner";
import parse from "html-react-parser";

const Leadership = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const uploadUrl = "https://backend.ayanapower.com/storage/team-member/";
  useEffect(() => {
    fetch("https://backend.ayanapower.com/api/get-leaders")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <section id="leaders">
        <div className="container ">
          <div className="row">
            {items.data.map((data) => (
              <div className="col-sm-6 col-lg-3 mb-3 " key={data.id}>
                <div className="box shadow-sm p-4">
                  <div className="image-wrapper mb-3 ">
                    <img
                      className=""
                      src={`${uploadUrl}${data.image}`}
                      alt="..."
                    />
                  </div>
                  <div className="box-desc mb-2">
                    <h5>{data.name}</h5>
                    <h5>{parse(data.designation)}</h5>
                  </div>
                  <ul className="social">
                    <li>
                      <NavHashLink to={`/leadership/${data.slug}#`}>
                        <button className="buttonStyle">Know More</button>
                      </NavHashLink>
                      {/* <a
                        href={data.linkedin_url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a> */}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
};
export default Leadership;
