import { React , useEffect , useState  } from "react";
import { Link } from "react-router-dom";
import "./sustainability.scss";

import peopleImg from "../../assets/img/sustainability/people.png";
import environment from "../../assets/img/sustainability/environment.png";
import planet from "../../assets/img/sustainability/planet.png";
import sustainability_banner from "../../assets/img/sustainability/sustainability-banner.png";

// import bg from "../../assets/img/sustainability/bg.jpg";
import img4 from "../../assets/img/sustainability/skilldevelopment.png";
import img5 from "../../assets/img/sustainability/img5.png";
import sustainability_img from "../../assets/img/sustainability_img.png";

import img1 from "../../assets/img/planet_prosperity/icon1.svg";
import img2 from "../../assets/img/planet_prosperity/icon2.svg";
import img3 from "../../assets/img/planet_prosperity/icon3.svg";

import policy_img1 from "../../assets/img/sustainability/policies/desk-based-environmental-and-social-due-diligence-report_andhra-pradesh.png";
import policy_img2 from "../../assets/img/sustainability/policies/environmental-and-social-impact-assessmen-of-a-300-mw-solar-power-projec-in-bikaner-rajasthan.png";
import policy_img3 from "../../assets/img/sustainability/policies/ayana-brownfield-acquisition-abridged-esdd.png";
import policy_img4 from "../../assets/img/sustainability/policies/ayana-brownfield-acquisition-abridged-tamil.png";
import policy_img5 from "../../assets/img/sustainability/policies/environmental-and-social-management-system-tamil.png";
import policy_img6 from "../../assets/img/sustainability/policies/environmental-social-management-system.png";
import policy_img7 from "../../assets/img/sustainability/policies/report-of-esia-study-for-250-mw-solar-power-project-telugu.png";
import policy_img8 from "../../assets/img/sustainability/policies/andhra-pradesh-250-mw-under-construction-desk-telugu.png";
import policy_img9 from "../../assets/img/sustainability/policies/update-report-of-esia-study-for-250-mw-solar-power-project.png";
import policy_img10 from "../../assets/img/sustainability/policies/report-of-esia-study-for-250-mw-solar-power-project.png";
import policy_img11 from "../../assets/img/sustainability/policies/tirunelveli-solar-project-private-limited-disclosure.png";
import policy_img12 from "../../assets/img/sustainability/policies/environmental-and-social-screening-for-300-mw-wind-power-project-gadag-karnataka.png";
// import policy_img13 from "../../assets/img/sustainability/policies/posh-policy.png";
import policy_img14 from "../../assets/img/sustainability/policies/1.png";
import policy_img15 from "../../assets/img/sustainability/policies/2.png";
import policy_img16 from "../../assets/img/sustainability/policies/3.png";
import Procedure_Emergency_Img from "../../assets/img/sustainability/Procedure_Emergency_Img.png";
import WASTE_MANAGEMENT_img from "../../assets/img/sustainability/WASTE_MANAGEMENT_img.png";
import Cultural_Heritage_Management_img from "../../assets/img/sustainability/Cultural_Heritage_Management_img.png";
import Business_Continuity_img from "../../assets/img/sustainability/Business_Continuity_img.png";
import Breeding_Season_Bird_Survey_img from "../../assets/img/sustainability/Breeding_Season_Bird_Survey_img.png";
import GHG_Intensity_img from "../../assets/img/sustainability/GHG_Intensity_img.png";
import Late_Migratory_Season_Bird_img from "../../assets/img/sustainability/Late_Migratory_Season_Bird_img.png";
import LSE_ayana_case_study from "../../assets/img/sustainability/LSE-ayana-case-study.jpg";

import CSRImg from "../../assets/img/sustainability/csr.png";
import QHSEImg from "../../assets/img/sustainability/qhse.png";
import GrievanceImg from "../../assets/img/sustainability/grevience.png";
import LabourWorkingImg from "../../assets/img/sustainability/labours-working-condition.png";
import SecurityPolicyImg from "../../assets/img/sustainability/safety-security.png";
import GenderBalanceImg from "../../assets/img/sustainability/policies/gender-balance-diversity-policy.png";
import CommunityDevelopment from "../../assets/img/sustainability/policies/community-development.png";
import evironmental_monitoring_img from "../../assets/img/sustainability/policies/evironmental_monitoring.png";
import Environmental_and_Social_aspect from "../../assets/img/sustainability/Environmental_and_Social_aspect.png";
import sustainabilityReportJune2023 from "../../assets/img/sustainability/policies/ayana_renewable_power_sustainability_report_june_2023.png";
import placeholder from "../../assets/img/sustainability/policies/placeholder.png";
import ISS_ESG from "../../assets/img/sustainability/ISS_ESG.png";
import ISM_IMAGE from "../../assets/img/sustainability/ism.png";
import Brief_Report from "../../assets/pdf/Sustainability/Brief_Report.pdf";
import ISM from "../../assets/pdf/Sustainability/ISO_Information_Security_management_system.pdf";
import GenderBalanceandDiversityPolicy from "../../assets/pdf/CorporateGoverence/GenderBalanceandDiversityPolicy.pdf";
import Grievance from "../../assets/pdf/CorporateGoverence/grievance.pdf";
import sustainabilityReportJune2023pdf from "../../assets/pdf/Sustainability/sustainabilityReportJune2023.pdf";
import CommunityDevelopmentFramework from "../../assets/pdf/Sustainability/CommunityDevelopmentFramework.pdf";
import CSRPolicyAndCommitteeComposition from "../../assets/pdf/Sustainability/CSR-Policy-and-Committee-Composition.pdf";
import LabourandWorkingConditionPolicy from "../../assets/pdf/CorporateGoverence/LabourandWorkingConditionPolicy.pdf";
import SecurityPolicyStatementDraftCDCcomments from "../../assets/pdf/CorporateGoverence/SecurityPolicyStatementDraftCDCcomments.pdf";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import pdf1 from "../../assets/pdf/Sustainability/20-July-2020-Final-ESDD-250MW-Ananthapurum-Solar-PV-Project.pdf";
import pdf2 from "../../assets/pdf/Sustainability/300-MW-Solar-Bikaner-Exe-Summary.pdf";
import pdf3 from "../../assets/pdf/Sustainability/Ayana-Brownfield-Acquisition-Abridged-ESDD.pdf";
import pdf4 from "../../assets/pdf/Sustainability/Ayana-Brownfield-Acquisition-Abridged-ESDD-Tamil.pdf";
import pdf5 from "../../assets/pdf/Sustainability/Environmental-and-Social-Management-System-Tamil.pdf";
import pdf6 from "../../assets/pdf/Sustainability/esmp-ayana.pdf";
import pdf7 from "../../assets/pdf/Sustainability/ES-Telugu-Final.pdf";
import pdf8 from "../../assets/pdf/Sustainability/ES-telugu-july20.pdf";
import pdf9 from "../../assets/pdf/Sustainability/Final-0557252-250-MW-Ananthapuramu-Solar-plant.pdf";
import pdf10 from "../../assets/pdf/Sustainability/Final-Certificate-Ayana-Power-IMS-1.pdf";
import pdf11 from "../../assets/pdf/Sustainability/Final-Certificate-Ayana-Power-IMS-2.pdf";
import pdf12 from "../../assets/pdf/Sustainability/Final-Certificate-Ayana-Power-IMS-3.pdf";
import pdf13 from "../../assets/pdf/Sustainability/Labour-and-Working-Condition-Policy.pdf";
// import pdf14 from "../../assets/pdf/Sustainability/POSHPolicy.pdf";
// import pdf15 from "../../assets/pdf/Sustainability/QHSE.pdf";
// import pdf16 from "../../assets/pdf/Sustainability/Quality-Health-Safety-and-Environment-Policy.pdf";
import pdf17 from "../../assets/pdf/Sustainability/Report of ESIA study for 250 MW Solar Power Project.pdf";
import pdf18 from "../../assets/pdf/Sustainability/Tirunelveli-Solar-Project-Private-Limited.pdf";
import pdf19 from "../../assets/pdf/Sustainability/Wind-Project-Area-Screening-Report.pdf";
import evironmental_monitoring from "../../assets/pdf/Sustainability/evironmental_monitoring.pdf";
import Ayana_Breeding_Season_Bird from "../../assets/pdf/Sustainability/Ayana_Breeding_Season_Bird.pdf";
import Site_selection_Criteria from "../../assets/pdf/Sustainability/Site_selection_Criteria.pdf";
import QHSE from "../../assets/pdf/Sustainability/QHSE.pdf";
import Procedure_Emergency_Preparedness from "../../assets/pdf/Sustainability/Procedure_Emergency_Preparedness.pdf";
import WASTE_MANAGEMENT from "../../assets/pdf/Sustainability/WASTE_MANAGEMENT.pdf";
import Business_Continuity_Policy from "../../assets/pdf/Sustainability/Business_Continuity_Policy.pdf";
import Cultural_Heritage_Management_plan from "../../assets/pdf/Sustainability/Cultural_Heritage_Management_plan.pdf";
import GHG_Intensity from "../../assets/pdf/Sustainability/GHG_Intensity.pdf";
import Late_Migratory_Season_Bird from "../../assets/pdf/Sustainability/Late_Migratory_Season_Bird.pdf";
import LSE_Ayana_case_study_30_April from "../../assets/pdf/Sustainability/LSE-Ayana-case-study-30-April.pdf";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

import ISO_9001 from "../../assets/img/sustainability/ISO-9001.pdf";
import ISO_9001_IMG from "../../assets/img/sustainability/9001_img.PNG";
import ISO_14001 from "../../assets/img/sustainability/ISO-14001.pdf";
import ISO_14001_IMG from "../../assets/img/sustainability/14001_img.PNG";
import ISO_45001 from "../../assets/img/sustainability/ISO-45001.pdf";
import ISO_45001_IMG from "../../assets/img/sustainability/45001_img.PNG";

import config from "../../config";
import Spinner from "../../components/Spinner/Spinner";

const Sustainability = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const uploadUrl = "https://backend.ayanapower.com/storage/sustainablility_policy/";
  useEffect(() => {
    fetch(`${config.base_url}get-sustainablility-policies`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);


  return (
    <div className="sustainablity">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"sustainability"}
      >
        <img src={sustainability_banner} alt="" className="img-fluid w-100" />
        <div className="container-fluid"></div>
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Sustainability</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Sustainability
          </p>
        </div>
      </section>

      <section className="case-study-section mt-4">
        <div className="container">
          <div className="row">
            <div className="col-md-9 d-flex align-items-start justify-content-center flex-column">
              <h3>London School of Economics Case Study on Ayana</h3>
              <p className="mb-4">
                Ayana - building a skills base for India's clean energy
                transition
              </p>
              <a
                target="__blank"
                href="https://justtransitionfinance.org/casestudy/ayana-building-a-skills-base-for-indias-clean-energy-transition/"
              >
                https://justtransitionfinance.org/casestudy/ayana-building-a-skills-base-for-indias-clean-energy-transition/
              </a>
            </div>
            <div className="col-md-3 d-flex align-items-center justify-content-center">
              <a target="__blank" href={LSE_Ayana_case_study_30_April}>
                <img
                  className="img-fluid"
                  src={LSE_ayana_case_study}
                  alt="LSE_ayana_case_study"
                />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img src={sustainability_img} alt="" className="w-100 " />
            </div>
            <div className="col-md-8 vision_mission_block ps-4">
              <p>
                Sustainability is at the core of what Ayana Renewable Power
                does. The company is committed to promoting a green planet
                through its projects that provide clean, reliable, and
                affordable energy to communities while minimizing the impact on
                the environment. Ayana's initiatives help to reduce carbon
                emissions, create job opportunities, promote skill development,
                and improve the standard of living of communities. Through its
                efforts, Ayana is helping build a sustainable future for
                generations to come.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        {/* people */}
        <div className="container">
          <div className="row py-2 p-mb-2 align-items-center">
            <div className="col-md-12 col-lg-8 order-2 order-md-1">
              <h6 className="text_blue font_20">People</h6>
              <p className=" paraCsr">
                Ayana Renewable Power is making a positive impact on the lives
                of people in the communities around its renewable energy
                projects. The company's skill development programs for youth
                help to create a skilled workforce for the renewable energy
                sector and promote entrepreneurship. By empowering young people
                with technical skills and encouraging entrepreneurship, Ayana
                creates job opportunities while contributing to sustainable
                development. The impact of these programs extends beyond job
                creation, as they are creating a better-equipped workforce to
                meet future challenges and inspiring a sense of purpose in young
                people and women. Ayana's social responsibility is evident in
                its efforts to create a more inclusive and sustainable future
                for all.
              </p>
            </div>
            <div className="col-md-12 col-lg-4 order-lg-1">
              <img src={peopleImg} className="w-100 " alt="" />
              {/* <div className="bgGreen">
                <p className="text-white px-4 p-1">
                  Solar Skill and Opportunity Centre, Ananthapuramu,
                  Andhra Pradesh
                </p>
              </div> */}
            </div>
          </div>
          {/* environment */}
          <div className="row py-2 p-mb-2 align-items-center">
            <div className="col-md-12 col-lg-4 p-mb-4 order-md-2 order-lg-1">
              <img src={environment} className="w-100 " alt="" />
            </div>
            <div className="col-md-12 col-lg-8 order-md-1">
              <h6 className="text_blue font_20">Environment</h6>
              <p className=" paraCsr">
                Ayana Renewable Power's microgrid project in Jharkhand has
                brought the local community access to electricity, improved
                their livelihoods, and contributed to the overall development of
                the community. The project generates solar energy, reducing the
                carbon footprint in the region, thereby leading to a greener
                environment. The electricity access to the regional community
                led to better irrigation of farm fields, which in turn resulted
                in increased agricultural productivity. Thus, the project has
                had a multi-tier effect on the region’s environment.{" "}
              </p>
            </div>
          </div>
          {/* planet */}
          <div className="row py-2 p-mb-2 align-items-center">
            <div className="col-md-12 col-lg-8 order-2 order-md-1">
              <h6 className="text_blue font_20">Planet</h6>
              <p className="paraCsr">
                Ayana Renewable Power is committed to sustainable development
                and reducing carbon emissions. The projects use renewable
                resources such as wind and solar power to generate clean energy
                and reduce reliance on fossil fuels. Ayana's initiatives not
                only help to protect the environment but also create job
                opportunities and improve the standard of living of the
                communities. Their efforts are making a significant impact on
                the world we live in and contribute to a more sustainable future
                for generations to come.
              </p>
            </div>
            <div className="col-md-12 col-lg-4 p-mb-4 order-1">
              <img src={planet} className="w-100 " alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="bg_grey pos_relative">
        <div className="container">
          <div className="row align-items-center text-aligin-center justify-content-center">
            <div className="col-md-8">
              <h5 className="planetProsperity">Pro Planet, Pro Community</h5>
            </div>
          </div>
        </div>

        <section className="card-container">
          <div className="card">
            <img className="cardImage" src={img1} alt="" />
            <p className="cardHeading">Reduce GHG emissions</p>
            <p className="cardBody">
              To support climate change mitigation (SDG 13A){" "}
            </p>
          </div>

          <div className="card">
            <img className="cardImage" src={img2} alt="" />
            <p className="cardHeading">Create economic opportunities</p>
            <p className="cardBody">Through firm growth (SDG 8.5) (SDG 13A) </p>
          </div>

          <div className="card">
            <img className="cardImage" src={img3} alt="" />
            <p className="cardHeading">Improve access</p>
            <p className="cardBody">
              To affordable, reliable, and clean electricity (SDGs 7.1 & 7.2){" "}
            </p>
          </div>
        </section>
      </section>

      <section>
        {/* video with content */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-4">
              <video
                width="100%"
                height="300"
                style={{ marginBottom: "-5px" }}
                controls
                muted
              >
                <source
                  src="https://ayanas3.s3.amazonaws.com/sustanability.mp4"
                  type="video/mp4"
                />
              </video>
              <p className="bgGreen text-white px-1 text-center">
                Skill Development Centre, <br />
                Ananthapuramu, Andhra Pradesh.
              </p>
            </div>
            <div className="col-md-12 col-lg-8">
              <p className="ml-0 ml-md-4 paraCsr">
                At Ayana Renewable Power, we are dedicated to promoting access
                to clean and renewable energy for all communities around our
                project area, especially those who have been historically
                excluded based on their income, gender, or class. We believe
                that access to clean energy is a basic human right and a crucial
                component of sustainable development. In addition to providing
                clean energy, we also strive to empower local communities by
                offering skills, employment, and entrepreneurship opportunities
                with a special focus on women, youth, and marginalised groups.
                We are committed to building a more inclusive and sustainable
                future for all. In order to prevent the severe consequences of
                climate change, it is crucial that we all play our role to move
                towards achieving a net zero global carbon footprint by 2070 as
                envisaged by the government of India in its NDC targets. This
                transformation requires businesses in all industries to adopt a
                low-carbon approach, and also help communities adopt a
                low-carbon lifestyle. At Ayana, we recognize the importance of
                creating more green energy jobs in our efforts to combat climate
                change. Also, we help the communities around our projects in the
                catchment areas access green energy rather than coal-based
                power. We are committed to supporting a fair and equitable
                transition, by investing in environmentally friendly businesses
                that offer new employment opportunities in low-carbon sectors
                and collaborating with our partners to create jobs that benefit
                local communities.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="">
        <div className="">
          <img className="peopleImg" src={bg} alt="" />
        </div>
      </section> */}

      {/* <section className="">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <p className=""></p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="">
        {/* in addition */}
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <p className="paraCsr">
                In addition to its commitment to sustainable energy production,
                Ayana places a strong emphasis on working with and supporting
                the local communities around the projects which it operates. As
                part of this initiative, Ayana collaborated with the UK’s
                Department for International Development (DFID) and the
                Self-Employed Women’s Association (SEWA) to launch a skills
                development program for communities residing near Ayana’s
                recently established solar power plant in Ananthapur, Andhra
                Pradesh. The program aimed to equip participants with the
                necessary training and skills to pursue employment opportunities
                at the solar plant, including training in operations and
                maintenance, as well as digital literacy and job readiness. The
                program was specifically designed to address the challenges that
                women face in accessing employment opportunities at the solar
                plant, due to prevalent perceptions about their ability to work
                in such an environment.
              </p>
            </div>
            <div className="col-md-12 col-lg-4">
              <img src={img4} className="w-100 " alt="" />
              <p className="bgGreen text-white px-1 text-center">
                Skill Development Centre, Bikaner, Rajasthan.
              </p>
            </div>
          </div>
          <div className="row py-4">
            <div className="col-md-12 col-lg-4 order-md-2 order-lg-1">
              <img src={img5} className="w-100 " alt="" />
              <p className="bgGreen text-white px-1 text-center">
                Skill Development Centre,
                <br /> Ananthapuramu, Andhra Pradesh.
              </p>
            </div>
            <div className="col-md-12 col-lg-8 order-md-1 order-lg-2">
              <p className="paraCsr">
                The skill development program launched by Ayana, DFID, and SEWA
                for the communities living near Ayana’s solar power plant in
                Ananthapur, Andhra Pradesh has proven to be highly successful.
                Of the 183 individuals who received training, one-third were
                hired immediately. The program not only supported Ayana's
                business interests by creating a skilled local workforce, but it
                also played a crucial role in securing livelihoods for local
                communities as economies transition towards sustainable,
                low-carbon pathways. Given the program's success, Ayana plans to
                replicate it in future power projects as well.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <center>
            <div className="title">
              <h4>Policies</h4>
            </div>
          </center>
          <div className="row g-3 mt-4 justify-content-center">

          {
            error ? (
              <div>Error: {error.message}</div>
            ):
             !isLoaded ? (
                <div>
                <Spinner />
                </div>
             ) : ( 
              items.data.map((data) => {
                  return <>
                           <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={`${uploadUrl}${data.image}`}
                    alt="Images"
                  className="img-fluid text-center  image_size"
                
                  style={{ width: "100%", aspectRatio: 16 / 9 }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p> {data.title} </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    {
                      data.pdf ? 
                      <a
                      className="pdf_link"
                      href={`${uploadUrl}${data.pdf}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a> : 
                    ''
                    }
                  
                  </div>
                </div>
              </div>
            </div>
                  </>
              })
             

             )
          }

            {/* 1st */}
            {/* Lokesh */}
            {/* <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={ISO_9001_IMG}
                  className="img-fluid text-center  image_size"
                  alt=""
                  style={{ width: "100%", aspectRatio: 16 / 9 }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>ISO 9001:2015 - Quality management systems</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={ISO_9001}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={ISO_14001_IMG}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>ISO 14001:2015 - Environmental management system</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={ISO_14001}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={ISO_45001_IMG}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      ISO 45001:2018 - Occupational health and safety management
                      systems
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={ISO_45001}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={ISM_IMAGE}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>ISMS Certificate ISO/IEC 27001:2022</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={ISM}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={ISS_ESG}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>ESG Corporate Rating</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Brief_Report}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={sustainabilityReportJune2023}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Ayana Renewable Power Sustainability Report June 2023</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={sustainabilityReportJune2023pdf}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={Procedure_Emergency_Img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Management Procedure For Emergency Preparedness And
                      Response
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Procedure_Emergency_Preparedness}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={WASTE_MANAGEMENT_img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Management Procedure For Waste Storage Handling And
                      Disposal
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={WASTE_MANAGEMENT}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={Breeding_Season_Bird_Survey_img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p> Business Continuity Policy</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Business_Continuity_Policy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={Cultural_Heritage_Management_img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Cultural Heritage Management Plan</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Cultural_Heritage_Management_plan}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={GHG_Intensity_img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>GHG Intensity</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={GHG_Intensity}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={Late_Migratory_Season_Bird_img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Late Migratory Season Bird Survey for 300 MW Gadag Wind
                      Farm
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Late_Migratory_Season_Bird}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={evironmental_monitoring_img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Environmental Monitoring</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={evironmental_monitoring}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={Business_Continuity_img}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Breeding Season Bird Survey for 300MW Gadag Wind Farm,
                      Karnataka
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Ayana_Breeding_Season_Bird}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={Environmental_and_Social_aspect}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Environmental and Social aspect in Wind and Solar Projects
                      Site Selection
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Site_selection_Criteria}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={sustainabilityReportJune2023}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Ayana Renewable Power Sustainability Report June 2023</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={sustainabilityReportJune2023pdf}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={CommunityDevelopment}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Community Development Framework </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={CommunityDevelopmentFramework}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={CSRImg}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>CSR Policy</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={CSRPolicyAndCommitteeComposition}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={QHSEImg}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>QHSE</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={QHSE}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={GrievanceImg}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Grievance</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={Grievance}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={LabourWorkingImg}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Labour-and-Working-Condition-Policy</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={LabourandWorkingConditionPolicy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={SecurityPolicyImg}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Security Policy Statement</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={SecurityPolicyStatementDraftCDCcomments}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={GenderBalanceImg}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Gender Balance and Diversity Policy</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={GenderBalanceandDiversityPolicy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img1}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-11 col-11">
                    <p style={{minHeight:'150px'}} >
                      Desk-based Environmental and Social Due Diligence Report
                      for 250 MW under- construction Solar PV Project in Andhra
                      Pradesh, India
                    </p>
                  </div>
                  <div className="col-md-1 col-1 text-center">
                    <a
                      className="pdf_link"
                      href={pdf1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img2}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p style={{minHeight:'150px'}}>
                      Environmental and Social Impact Assessment of a 300 MW
                      Solar Power Project in Bikaner, Rajasthan
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf2}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img3}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Ayana Brownfield Acquisition Abridged ESDD</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf3}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img4}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Ayana Brownfield Acquisition Abridged ESDD Tamil</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf4}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img5}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Environmental and Social Management System Tamil</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf5}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img6}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Environmental And Social Management System</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf6}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img7}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Report of ESIA study for 250 MW Solar Power Project-Telugu
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf7}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img8}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p style={{minHeight:'185px'}} >
                      In Andhra Pradesh, India 250 MW under construction Desk
                      for Solar PV Project Based rheumatism and Social Welfare
                      Report-Telugu
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf8}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img9}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Update Report of ESIA study for 250 MW Solar Power Project
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf9}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={placeholder}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Labour and Working Condition Policy</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf13}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img13}
                  className="img-fluid"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>POSH Policy</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf14}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={GenderBalanceImg}
                  className="img-fluid"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Title</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf15}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={GenderBalanceImg}
                  className="img-fluid"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Title</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf16}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img10}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>Report of ESIA study for 250 MW Solar Power Project</p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf17}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img11}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p>
                      Tirunelveli Solar Project Private Limited, Disclosure.
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf18}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="bi_shadow_box bg-white p-3">
                <img
                  src={policy_img12}
                  className="img-fluid image_size"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="row border-top pt-3 mt-3 px-2">
                  <div className="col-md-10 col-10">
                    <p style={{minHeight:'150px'}}  className="mb-3">
                      Environmental and Social Screening for 300 MW Wind Power
                      Project in Gadag, Karnataka
                    </p>
                  </div>
                  <div className="col-md-2 col-2 text-center">
                    <a
                      className="pdf_link"
                      href={pdf19}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Sustainability;
