// CookieConsent.js
import React, { useState, useEffect } from "react";
import { setCookie, getCookie } from './cookieUtils';
import "./cookie.css";

const CookieConsent = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const cookieAccepted = getCookie("cookieConsent");
    if (cookieAccepted) {
      setAccepted(true);
    }
  }, []);

  const handleAccept = () => {
    setCookie("cookieConsent", true, {
      path: "/",
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
    });
    setAccepted(true);
  };

  const handleCancel = () => {
    setAccepted(true);
  };

  if (accepted) {
    return null;
  }

  return (
    <>
      <section class=" cookies-accept">
        <div class="container py-3 text-white">
          <div class="row align-items-center">
            <div class="col-md-7 mb-3 mb-md-0">
              <h5 class="mb-2">
                We use Cookies on our website. Some of them are essential, while
                other help us improve this website and your experience
              </h5>
              <span>
                <a href="#">Privacy Policy</a> | <a href="#">Imprint</a>
              </span>
            </div>
            <div class="col-md-5">
              <div class="row align-items-center justify-content-center justify-content-md-center">
                <div class="col-md-6 col-5 text-start text-md-end text-lg-end">
                  <button
                    onClick={handleAccept}
                    class="btn btn-light radius-50"
                  >
                    Accept all
                  </button>
                </div>
                <div class="col-md-6 col-5 text-start text-md-start  text-lg-start">
                  <button
                    onClick={handleCancel}
                    class="btn btn-outline-light radius-50"
                  >
                    Deny
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CookieConsent;
