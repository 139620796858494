import { SingIN } from "../../../network/admin";
import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { authenticate, isAuth } from "../../../network/helper";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [values, setValues] = useState({});
  const navigate = useNavigate();

  const handelSignin = async (e) => {
    e.preventDefault();
    if (values?.email && values.password) {
      try {
        const resp = await SingIN(values);
        if (resp?.data) {
          authenticate(resp, () => {
            isAuth()
              ? navigate("/admin/dashboard")
              : toast.error("Something went wrong");
          });
        } else {
          toast.error(resp?.errRes?.data?.error);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };

  return (
    <div className="h-screen d-flex align-items-center justify-content-center flex-column">
      <ToastContainer />
      <div className="text-center mt-5">
        <div className="d-flex justify-content-center img-fluid w-25 mx-auto">
          <svg
            fill="none"
            viewBox="0 0 24 24"
            className="w-12 h-12 text-primary"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
            />
          </svg>
        </div>
        <h2 className="text-5xl fw-bold">Sign in to your account</h2>
      </div>
      <br /> <br />
      <div className="d-flex justify-content-center my-2 mx-4 md-mx-0">
        <form
          onSubmit={handelSignin}
          className="w-100 max-w-xl bg-white rounded shadow p-4"
        >
          <div className="mb-3">
            <label
              className="form-label fw-bold text-gray-700 fs-5"
              htmlFor="email"
            >
              Email address
            </label>
            <input
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              className="form-control form-control-lg bg-white text-gray-900 fw-medium border border-gray-400 rounded py-3 px-3"
              type="email"
              id="email"
              name="email"
              required
            />
          </div>
          <div className="mb-3">
            <label
              className="form-label fw-bold text-gray-700 fs-5"
              htmlFor="password"
            >
              Password
            </label>
            <input
              onChange={(e) =>
                setValues({ ...values, password: e.target.value })
              }
              className="form-control form-control-lg bg-white text-gray-900 fw-medium border border-gray-400 rounded py-3 px-3"
              type="password"
              id="password"
              name="password"
              required
            />
          </div>
          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-primary fw-bold border border-gray-200 rounded py-3 px-3 hover-bg-blue-500 focus-outline-none focus-bg-white focus-border-gray-500"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
