import React, { useEffect, useState } from "react";
import "./directors-board.scss";
import parse from "html-react-parser";

import Spinner from "../Spinner/Spinner";
import { NavHashLink } from "react-router-hash-link";

const DirectorsBoard = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const uploadUrl = "https://backend.ayanapower.com/storage/team-member/";
  useEffect(() => {
    fetch("https://backend.ayanapower.com/api/get-board-of-directors")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <section className="">
        <div className="container">
          <div className="row">
            {items.data.map((data) => (
              <div className="col-sm-6 col-lg-3 mb-3 " key={data.id}>
                <div className="box shadow-sm p-4 ">
                  <div className="image-wrapper mb-3">
                    <img
                      className="img-fluid"
                      src={`${uploadUrl}${data.image}`}
                      alt="person"
                    />
                  </div>
                  <div className="box-desc">
                    <h5>{data.name}</h5>
                  </div>
                  <div className="box-desc mb-2">
                    <p>{parse(data.designation)}</p>
                  </div>
                  <ul className="social">
                    <li className="">
                      <NavHashLink
                        className="navlink"
                        to={`/director-board/${data.slug}#`}
                      >
                        <button className="buttonStyle ">Know More</button>
                      </NavHashLink>
                      {/* <a
                        href={data.linkedin_url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <i className="fab fa-linkedin"></i>
                      </a> */}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
};

export default DirectorsBoard;
