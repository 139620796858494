import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Fun1 from "../../assets/img/HomeLogoSliderImages/fun1.png";
import Fun2 from "../../assets/img/HomeLogoSliderImages/fun2.png";
import Fun3 from "../../assets/img/HomeLogoSliderImages/fun3.png";
import Fun4 from "../../assets/img/HomeLogoSliderImages/fun4.png";
import Fun5 from "../../assets/img/HomeLogoSliderImages/fun5.png";
import Fun6 from "../../assets/img/HomeLogoSliderImages/fun6.png";

const responsive = {
  xldesktop: {
    breakpoint: { max: 2526, min: 1920 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const HomeSliderImages = () => {
  return (
    <section className="">
      <div className="">
        <Carousel
          autoPlay={true}
          infinite
          responsive={responsive}
          itemClass="p-2"
        >
          <img className="w-100 h-100" src={Fun1} alt="arrow" />
          <img className="w-100 h-100" src={Fun2} alt="arrow" />
          <img className="w-100 h-100" src={Fun3} alt="card" />
          <img className="w-100 h-100" src={Fun4} alt="arrow" />
          <img className="w-100 h-100" src={Fun5} alt="arrow" />
          <img className="w-100 h-100" src={Fun6} alt="arrow" />
        </Carousel>
      </div>
    </section>
  );
};

export default HomeSliderImages;
