import Celebration from "../../assets/img/ayana_life/trust.png";
import Collaboration from "../../assets/img/ayana_life/collaboration.png";
import Communication from "../../assets/img/ayana_life/mutual-respect.png";
import Cooperation from "../../assets/img/ayana_life/cooperation.png";

import "./infographic.scss";

const Infographic = () => {
  return (
    <>
      <section id={"work-with-us"}>
        <div className="infographics_container">
          {/* <center>
            <div className="title pt-4">
              <h4>Why work with Us</h4>
            </div>
          </center> */}
          <div className="row justify-content-center">
            <section className="inforgraphicContainer">
              <div className="infographicCard">
                <img
                  className="infographicCardImage"
                  src={Celebration}
                  alt=""
                />
                <div className="test">
                  <h4 className="infographicHeadingText">Trust </h4>
                </div>
              </div>
              <div className="infographicCard">
                <img
                  className="infographicCardImage"
                  src={Collaboration}
                  alt=""
                />
                <div className="test">
                  <h4 className="infographicHeadingText">Collaboration </h4>
                </div>
              </div>
              <div className="infographicCard">
                <img
                  className="infographicCardImage"
                  src={Communication}
                  alt=""
                />
                <div className="test">
                  <h4 className="infographicHeadingText">Mutual Respect </h4>
                </div>
              </div>
              <div className="infographicCard">
                <img
                  className="infographicCardImage"
                  src={Cooperation}
                  alt=""
                />
                <div className="test">
                  <h4 className="infographicHeadingText">Cooperation </h4>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default Infographic;
