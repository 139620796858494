import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import "./pagination.scss";
import mapIcon from "../../assets/mapIcon.svg";
import experienceIcon from "../../assets/experience.svg";
import legalIcon from "../../assets/legal.svg";
import { Link } from "react-router-dom";
import Spinner from "../Spinner/Spinner";
import { NavHashLink } from "react-router-hash-link";
import moment from "moment";

function Items({ jobData }) {
  const [error, setError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  console.log("myjobs", jobData);

  useEffect(() => {}, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div>
        {jobData &&
          jobData.map((data) => (
            <section className="jobCardContainer bg_grey mb-2" key={data.id}>
              <div className="container text-center cardContainer">
                <div className="row g-3">
                  <div className="col-md-8 text-left">
                    <p className="jobTitle">{data.name} </p>
                    <p>
                      <span>Posted On: </span>
                      {moment(data.created_at).format("DD MMM, YYYY")}
                    </p>
                    <hr />
                    <div className="d-flex flex-wrap gap-4 jobLocation">
                      <p className="d-flex gap-2">
                        <img src={mapIcon} alt="location" />
                        {data.location.name}
                      </p>
                      <p className="d-flex gap-2">
                        <img src={legalIcon} alt="location" />
                        {data.type}
                      </p>
                      <p className="d-flex gap-2">
                        <img src={experienceIcon} alt="location" />
                        {data.experience}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="d-flex gap-3 flex-wrap">
                      <Link to={`/job-details/${data.slug}`}>
                        <button className="btn btn_blue ">View Details</button>
                      </Link>
                      <NavHashLink to={`/apply-now/${data.id}`}>
                        <button className="btn btn_blue">Apply Now</button>
                      </NavHashLink>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-flex flex-wrap gap-2 mt-2">
                      {JSON.parse(data.skills).map((i) => (
                        <p className="rounded-5 p-1 px-2 border">{i.value}</p>
                      ))}
                    </div>
                  </div>
                  {/* <div className="col-12 col-sm-6 col-md-6 col-xl-6 mt-2">
                    <span>
                      Posted On:{" "}
                      {moment(data.created_at).format("DD MMM, YYYY")}
                    </span>
                  </div> */}
                </div>
              </div>
            </section>
          ))}
      </div>
    );
  }
}
export default function Pagination({ jobs, itemsPerPage }) {
  console.log(itemsPerPage);
  console.log(jobs.length);
  // We start with an empty list of items.
  const [jobData, setjobData] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setjobData(jobs);
    setPageCount(Math.ceil(jobs.length / itemsPerPage));
  }, [jobs]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setjobData(jobs.slice(itemOffset, endOffset));
  }, [jobs, itemOffset]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % jobs.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <Items jobData={jobData} />
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="Prev"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
      />
    </>
  );
}
