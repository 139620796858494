import React from "react";
import "./projects_table.scss";
import SolarProject1 from "../../assets/img/accordian_operation/solar-project1.png";
import SolarProject2 from "../../assets/img/accordian_operation/solar-project2.png";
import SolarProject3 from "../../assets/img/accordian_operation/solar-project3.png";
import SolarProject4 from "../../assets/img/accordian_operation/solar-project4.png";
import SolarProject5 from "../../assets/img/accordian_operation/solar-project5.png";
import SolarProject6 from "../../assets/img/accordian_operation/solar-project6.png";
import SolarProject7 from "../../assets/img/accordian_operation/solar-project7.png";
import SolarProject8 from "../../assets/img/accordian_operation/solar-project8.png";
import SolarProject9 from "../../assets/img/accordian_operation/solar-project9.png";
import SolarProject10 from "../../assets/img/accordian_operation/solar-project10.png";
import SolarProject11 from "../../assets/img/accordian_operation/solar-project11.png";
import SolarProject12 from "../../assets/img/accordian_operation/solar-project12.png";
import SolarProject13 from "../../assets/img/accordian_operation/solar-project13.png";
import SolarProject14 from "../../assets/img/accordian_operation/solar-project14.png";
import chittorgarh from "../../assets/img/accordian_operation/chittorgarh.png";
import projects_img from "../../assets/img/projects_img.png";
import Solar_Ananthapuram from "../../assets/pdf/Offering and Projects/Solar_Ananthapuram.pdf";
import Solar_Bikaner from "../../assets/pdf/Offering and Projects/Solar_Bikaner.pdf";
import Solar_Chikodi from "../../assets/pdf/Offering and Projects/Solar_Chikodi.pdf";
import Solar_Nargund from "../../assets/pdf/Offering and Projects/Solar_Nargund.pdf";
import Solar_Pavagada from "../../assets/pdf/Offering and Projects/Solar_Pavagada.pdf";
import Solar_Tuticorin from "../../assets/pdf/Offering and Projects/Solar_Tuticorin.pdf";
import Solar_Bhadla from "../../assets/pdf/Offering and Projects/Solar_Bhadla.pdf";
import Solar_Chittorgarh from "../../assets/pdf/Offering and Projects/Solar_Chittorgarh.pdf";

export const ProjectsAccordian = () => {
  return (
    <div>
      <section id={"projects"}>
        <div className="container">
          <div className="row text-center pos_relative">
            <center>
              <div className="title pt-4">
                <h4>Projects</h4>
              </div>
            </center>
          </div>

          <div className="table-responsive">
            <table className="table table-striped ">
              <thead>
                <tr className="tableRow">
                  <th>S.No</th>
                  <th>Project Image</th>
                  <th>Project Type</th>
                  <th>Location</th>
                  <th>Capacity (MW AC)</th>
                  <th>Project Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <th>
                    <img src={SolarProject1} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Ananthapuram}>
                  Ananthpuram, AP</a></td>
                  <td>250</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>2</td>
                  <th>
                    <img src={SolarProject2} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Bikaner}>
                    Bikaner, Rajasthan</a></td>
                  <td>300</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>3</td>
                  <th>
                    <img src={SolarProject3} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Chikodi}>
                    Chikkodi, Karnataka</a></td>
                  <td>20</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>4</td>
                  <th>
                    <img src={SolarProject3} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Nargund}>
                    Nargund, Karnataka</a></td>
                  <td>20</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>5</td>
                  <th>
                    <img src={SolarProject4} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Pavagada}>
                    Pavagada, Karnataka</a></td>
                  <td>300</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>6</td>
                  <th>
                    <img src={SolarProject5} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Tuticorin}>
                    Tuticorin, Tamil Nadu</a></td>
                  <td>100</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>7</td>
                  <th>
                    <img src={SolarProject6} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Bhadla}>
                    Bhadla Solar Park, Rajasthan</a>​</td>
                  <td>50</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>8</td>
                  <th>
                    <img src={chittorgarh} alt="" />
                  </th>
                  <th>Solar</th>
                  <td><a href={Solar_Chittorgarh}>
                    Chittorgarh, Rajasthan</a>​</td>
                  <td>250</td>
                  <td>Commissioned</td>
                </tr>
                <tr>
                  <td>9</td>
                  <th>
                    <img src={SolarProject7} alt="" />
                  </th>
                  <th>Solar</th>
                  <td>Kadapa Solar Park, AP​</td>
                  <td>250</td>
                  <td>Under Development</td>
                </tr>
                <tr>
                  <td>10</td>
                  <th>
                    <img src={SolarProject8} alt="" />
                  </th>
                  <th>Solar</th>
                  <td>Bikaner 2, Rajasthan​</td>
                  <td>300</td>
                  <td>Under Development</td>
                </tr>
                {/* <tr>
                  <td>9</td>
                  <th>
                    <img src={SolarProject9} alt="" />
                  </th>
                  <th>Solar</th>
                  <td>Pavagada, Karnataka​</td>
                  <td>500</td>
                  <td>Under Development</td>
                </tr> */}
                {/* <tr>
                  <td>11</td>
                  <th>
                    <img src={SolarProject10} alt="" />
                  </th>
                  <th>Solar</th>
                  <td>Gadag, Karnataka​</td>
                  <td>300</td>
                  <td>Under Development</td>
                </tr> */}
                {/* <tr>
                  <td>12</td>
                  <th>
                    <img src={SolarProject11} alt="" />
                  </th>
                  <th>Solar</th>
                  <td>Koppal, Karnataka​</td>
                  <td>300</td>
                  <td>Under Development</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectsAccordian;
