import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { isAuth, logout } from "../../../network/helper";
import { getBlogDetails, updateBlog } from "../../../network/admin";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import useImageUpload from "../../../hooks/imageUploads";
import { useNavigate } from "react-router-dom";

const BlogsEdit = () => {
  const { imageUrl, handleImageChange } = useImageUpload();
  const navigate = useNavigate();
  const { id } = useParams();
  useEffect(() => {
    if (!isAuth()) {
      logout();
    } else {
    }
  }, []);
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const getBlogDetailsByAPi = async () => {
    try {
      const resp = await getBlogDetails(id);
      setValues({
        ...values,
        ...resp,
        metaKeywords: await getMetaKeywordsString(resp?.meta_details?.keywords),
      });
      console.log(values);
    } catch (error) {}
  };

  const getMetaKeywordsString = async (keywordsArray) => {
    if (!Array.isArray(keywordsArray) || keywordsArray.length === 0) {
      return "";
    }
    return keywordsArray.join(", ");
  };

  const getMetaKeywordsArray = async (metaKeywords) => {
    console.log(metaKeywords);
    if (!metaKeywords) {
      return [];
    }

    return await metaKeywords.split(",").map((keyword) => keyword.trim());
  };

  const handleBlogUpdate = async (event) => {
    event.preventDefault();
    setValues({
      ...values,
      meta_details: {
        ...values.meta_details,
        keywords: await getMetaKeywordsArray(values?.metaKeywords),
      },
      metaKeywords: null,
    });
    try {
      const resp = await updateBlog(values, id);
      if (resp?.data) {
        toast.success(resp?.data?.message);
        setValues((prevValues) => {
          const clearedValues = {};
          for (const key in prevValues) {
            clearedValues[key] = "";
          }
          return clearedValues;
        });
        setTimeout(() => {
          navigate(-1);
        }, [900]);
      } else {
        toast.error(resp?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getBlogDetailsByAPi();
  }, [id]);

  function extractDate(dateTimeString) {
    const date = new Date(dateTimeString);

    // Extract date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    // Formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("meta_details.")) {
      const nestedField = name.split(".")[1];
      setValues((prevData) => ({
        ...prevData,
        meta_details: {
          ...prevData.meta_details,
          [nestedField]: value,
        },
      }));
    } else {
      setValues((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleDateChange = (date) => {
    setValues({
      ...values,
      createdAt: date,
    });
  };

  console.log(values?.metaKeywords);
  return (
    <div className="card">
      <ToastContainer />
      <div className="card-body py-4">
        <form
          className="form"
          id="blog-blog"
          onSubmit={handleBlogUpdate}
          encType="multipart/form-data"
        >
          <input
            type="hidden"
            name="published_at"
            value={values?.publishedAt}
          />

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2 ">
            <div className="col-8">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span className="required">Title</span>
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control form-control-solid"
                  value={values.title}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span className="required">Posted At</span>
                </label>{" "}
                <br />
                <DatePicker
                  selected={values.createdAt}
                  onChange={handleDateChange}
                  className="form-control form-control-solid"
                  dateFormat="yyyy-MM-dd"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span className="required">Description</span>
                </label>
                <textarea
                  className="form-control form-control-solid"
                  name="description"
                  value={values.description}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
            <div className="col">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span>Image</span>
                </label>
                <input
                  type="file"
                  name="file1"
                  accept=".jpg,.png,.gif,.jpeg,.svg"
                  className="hidden"
                  onChange={async (e) => {
                    setLoading(true);
                    await handleImageChange(e);
                    setValues({ ...values, image: imageUrl });
                    setLoading(false);
                  }}
                />
                {loading && <div>loading....</div>}
                {values?.image && !loading && (
                  <img
                    className="img-fluid w-25"
                    src={values?.image}
                    alt="ayana"
                  />
                )}
              </div>
            </div>
            <div className="col">
              <div className="row mb-3">
                <label className="col-form-label col-sm-3 col-12 fs-6 fw-bold mt-3">
                  <span className="required">Visibility</span>
                </label>
                <div className="col-sm-9 row">
                  <div className="form-check col-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="visibilityPublic"
                      value={1}
                      checked={values.visibility === 1}
                      onChange={handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="visibilityPublic"
                    >
                      Public
                    </label>
                  </div>
                  <div className="form-check col-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="visibilityPrivate"
                      value={2}
                      checked={values.visibility === 2}
                      onChange={handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="visibilityPrivate"
                    >
                      Private
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
              >
                Meta Details
              </a>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <div className="row">
                <div className="col">
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-bold form-label mt-3">
                      <span>Meta Title</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      name="meta_details.title"
                      value={values.meta_details?.title}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-bold form-label mt-3">
                      <span>Meta Description</span>
                    </label>
                    <textarea
                      className="form-control form-control-solid"
                      name="meta_details.description"
                      value={values.meta_details?.description}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-bold form-label mt-3">
                      <span>Meta Keywords</span>
                    </label>
                    <textarea
                      className="form-control form-control-solid"
                      name="metaKeywords"
                      value={values.metaKeywords}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="separator mb-6"></div>

          <div className="d-flex justify-content-end">
            <a href={`/admin/blog`} className="btn btn-light me-3">
              Cancel
            </a>
            {values.id ? (
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Publish
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogsEdit;
