import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import upload from "../../assets/upload.svg";
import "./vendor-form.scss";
import Navbar from "../../components/Navbar/Navbar";
import PreloaderForm from "../../components/PreloaderForm/PreloaderForm";
import vendor_banner from "../../assets/img/vendor_banner.png";

const VendorForm = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  /** Company Information */
  const [company_name, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postal_code, setPostalCode] = useState("");
  const [state, setStateJ] = useState("");
  const [country, setCountry] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [fax_number2, setFaxNumber2] = useState("");
  const [fax_number1, setFaxNumber1] = useState("");
  const [email, setEmail] = useState("");
  const [contact_person_name, setContactPersonName] = useState("");
  const [website, setWebsite] = useState("");
  /** End Company Information */

  /** Ownership Details */
  const [management_team, setManagementTeam] = useState("");
  const [directors_name, setDirectorsName] = useState("");
  const [shareholding, setShareholding] = useState("");
  const [
    is_politicians_or_bureaucrats_or_public_officials,
    setIsPoliticiansOrBureaucratsOrPublicOfficials,
  ] = useState("");
  const [
    politicians_or_bureaucrats_or_public_officials_details,
    setPoliticiansOrBureaucratsOrPublicOfficialsDetails,
  ] = useState("");
  /** End Ownership Details */

  /** Vendor Type */
  const [vendor_type, setVendorType] = useState("no");
  const [is_materials, setIsMaterials] = useState("no");
  const [vendor_type_certified_doc, setVendorTypeCertifiedDoc] = useState(null);
  /** End Vendor Type */

  /** Statutory Requirement */
  const [pan_number, setPanNumber] = useState("");
  const [pan_doc, setPanDoc] = useState(null);
  const [is_libale, setIsLiable] = useState("");
  const [tds_doc, setTdsDoc] = useState(null);
  const [tds_category, setTdsCategory] = useState("");
  const [rate_of_tds, setRateOfTds] = useState("");
  const [gst_number, setGstNumber] = useState("");
  const [gst_doc, setGstDoc] = useState(null);
  const [ssl_regn_number, setSslRegnNumber] = useState("");
  /** End Statutory Requirement */

  /** Bank Details */
  const [bank_name, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [bank_account_number, setBankAccountNumber] = useState("");
  const [ifsc_code, setIfscCode] = useState("");
  const [bank_address, setBankAddress] = useState("");
  const [account_type, setAccountType] = useState("");
  const [micr_code, setMicrCode] = useState("");
  /** Bank Details */

  /** Financial Information */
  const [turnover_2018_19_doc, setTurnover_2018_19_doc] = useState(null);
  const [turnover_2019_20_doc, setTurnover_2019_20_doc] = useState(null);
  const [turnover_2020_21_doc, setTurnover_2020_21_doc] = useState(null);

  const [it_return_2018_19_doc, setItReturn_2018_19_doc] = useState(null);
  const [it_return_2019_20_doc, setItReturn_2019_20_doc] = useState(null);
  const [it_return_2020_21_doc, setItReturn_2020_21_doc] = useState(null);

  const [networth, setNetworth] = useState("");
  /** End Financial Information */

  /** Organizational Strength */
  const [managerial_staff, setManagerialStaff] = useState("");
  const [supervisory_staff, setSupervisoryStaff] = useState("");
  const [skilled_labour, setSkilledLabour] = useState("");
  const [unskilled_labour, setUnskilledLabour] = useState("");
  /** End Organizational Strength */

  /**  List of Major Customers */
  const [customer_1, setCustomer_1] = useState("");
  const [customer_1_description, setCustomer_1_description] = useState("");
  const [customer_1_bill_2010_11, setCustomer_1_bill_2010_11] = useState("");

  const [customer_2, setCustomer_2] = useState("");
  const [customer_2_description, setCustomer_2_description] = useState("");
  const [customer_2_bill_2010_11, setCustomer_2_bill_2010_11] = useState("");

  const [customer_3, setCustomer_3] = useState("");
  const [customer_3_description, setCustomer_3_description] = useState("");
  const [customer_3_bill_2010_11, setCustomer_3_bill_2010_11] = useState("");

  const [customer_4, setCustomer_4] = useState("");
  const [customer_4_description, setCustomer_4_description] = useState("");
  const [customer_4_bill_2010_11, setCustomer_4_bill_2010_11] = useState("");

  const [customer_5, setCustomer_5] = useState("");
  const [customer_5_description, setCustomer_5_description] = useState("");
  const [customer_5_bill_2010_11, setCustomer_5_bill_2010_11] = useState("");

  /**  End List of Major Customers */

  /**  Quality System Particulars */
  const [is_company_iso_accredited, setIsCompanyIsoAccredited] = useState("");
  const [iso_doc, setIsoDoc] = useState(null);
  const [is_company_have_other_accredited, setIsCompanyHaveOtherAccredited] =
    useState("");
  const [annexure_doc, setAnnexureDoc] = useState(null);
  /**  End Quality System Particulars */

  /**  Documents to be enclosed */
  const [isPanCardDoc, setIsPanCardDoc] = useState("no");
  const [
    isGst_registration_certificate_doc,
    setIsGstRegistrationCertificateDoc,
  ] = useState("no");

  const [is_incorporations_doc, setIsIncorporationsDoc] = useState("no");
  const [incorporations_doc, setIncorporationsDoc] = useState(null);

  const [is_msme_doc, setIsMsmeDoc] = useState("no");
  const [msme_doc, setMsmeDoc] = useState(null);

  const [is_roc_doc, setIsRocDoc] = useState("no");
  const [roc_doc, setRocDoc] = useState(null);

  const [is_shareholding_pattern_doc, setIsShareholdingPatternDoc] =
    useState("no");
  const [shareholding_pattern_doc, setShareholdingPatternDoc] = useState(null);

  const [is_latest_telephone_bill_doc, setIsLatestTelephoneBillDoc] =
    useState("no");
  const [latest_telephone_bill_doc, setLatestTelephoneBillDoc] = useState(null);

  const [is_any_utility_bill_doc, setIsAnyUtilityBillDoc] = useState("no");
  const [any_utility_bill_doc, setAnyUtilityBillDoc] = useState(null);

  const [is_latest_bank_statement_doc, setIsLatestBankStatementDoc] =
    useState("no");
  const [latest_bank_statement_doc, setLatestBankStatementDoc] = useState(null);

  const [is_income_sales_service_tax_doc, setIsIncomeSalesServiceTaxDoc] =
    useState("no");
  const [income_sales_service_tax_doc, setIncomeSalesServiceTaxDoc] =
    useState(null);

  const [is_audited_balance_sheet_doc, setIsAuditedBalanceSheetDoc] =
    useState("no");
  const [audited_balance_sheet_doc, setAuditedBalanceSheetDoc] = useState(null);

  const [is_networth_certificate_by_CA_doc, setIsNetworthCertificateByCaDoc] =
    useState("no");
  const [networth_certificate_by_CA_doc, setNetworthCertificateByCaDoc] =
    useState(null);

  const [is_latest_order_book_doc, setIsLatestOrderBookDoc] = useState("no");
  const [latest_order_book_doc, setLatestOrderBookDoc] = useState(null);

  /** End Documents to be enclosed */

  async function registerUser(event) {
    event.preventDefault();
    setIsLoading(true);

    // ### Set Form Data Keys and Values
    const formData = new FormData();

    /** Company Information */

    formData.append("company_information[company_name]", company_name);
    formData.append("company_information[address]", address);
    formData.append("company_information[city]", city);
    formData.append("company_information[postal_code]", postal_code);
    formData.append("company_information[state]", state);
    formData.append("company_information[country]", country);
    formData.append("company_information[phone_number]", phone_number);
    formData.append("company_information[fax_number1]", fax_number1);
    formData.append("company_information[fax_number2]", fax_number2);
    formData.append("company_information[email]", email);
    formData.append(
      "company_information[contact_person_name]",
      contact_person_name
    );
    formData.append("company_information[website]", website);

    /** End Company Information */

    /** Ownership Details */
    formData.append("ownership_details[management_team]", management_team);
    formData.append("ownership_details[directors_name]", directors_name);
    formData.append("ownership_details[shareholding]", shareholding);
    formData.append(
      "ownership_details[is_politicians_or_bureaucrats_or_public_officials]",
      is_politicians_or_bureaucrats_or_public_officials
    );
    formData.append(
      "ownership_details[politicians_or_bureaucrats_or_public_officials_details]",
      politicians_or_bureaucrats_or_public_officials_details
    );
    /** End Ownership Details */

    /** Vendor Type */
    formData.append("vendor_type[vendor_type]", vendor_type);
    formData.append("vendor_type[is_materials]", is_materials);
    formData.append("vendor_type[certified_doc]", vendor_type_certified_doc);
    /** End Vendor Type */

    /** Statutory Requirement */
    formData.append("statutory_requirement[pan_number]", pan_number);
    formData.append("statutory_requirement[pan_doc]", pan_doc);
    formData.append("statutory_requirement[is_libale]", is_libale);
    formData.append("statutory_requirement[tds_doc]", tds_doc);
    formData.append("statutory_requirement[tds_category]", tds_category);
    formData.append("statutory_requirement[rate_of_tds]", rate_of_tds);
    formData.append("statutory_requirement[gst_number]", gst_number);
    formData.append("statutory_requirement[gst_doc]", gst_doc);
    formData.append("statutory_requirement[ssl_regn_number]", ssl_regn_number);
    /** End Statutory Requirement */

    /** Bank Details */
    formData.append("bank_details[bank_name]", bank_name);
    formData.append("bank_details[branch]", branch);
    formData.append("bank_details[bank_account_number]", bank_account_number);
    formData.append("bank_details[ifsc_code]", ifsc_code);
    formData.append("bank_details[bank_address]", bank_address);
    formData.append("bank_details[account_type]", account_type);
    formData.append("bank_details[micr_code]", micr_code);
    /** End Bank Details */

    /** Financial Information */
    formData.append(
      "financial_information[turnover_2018_19_doc]",
      turnover_2018_19_doc
    );
    formData.append(
      "financial_information[turnover_2019_20_doc]",
      turnover_2019_20_doc
    );
    formData.append(
      "financial_information[turnover_2020_21_doc]",
      turnover_2020_21_doc
    );
    formData.append(
      "financial_information[it_return_2018_19_doc]",
      it_return_2018_19_doc
    );
    formData.append(
      "financial_information[it_return_2019_20_doc]",
      it_return_2019_20_doc
    );
    formData.append(
      "financial_information[it_return_2020_21_doc]",
      it_return_2020_21_doc
    );
    formData.append("financial_information[networth]", networth);
    /** End Financial Information */

    /** Organizational Strength */
    formData.append(
      "organizational_strength[managerial_staff]",
      managerial_staff
    );
    formData.append(
      "organizational_strength[supervisory_staff]",
      supervisory_staff
    );
    formData.append("organizational_strength[skilled_labour]", skilled_labour);
    formData.append(
      "organizational_strength[unskilled_labour]",
      unskilled_labour
    );
    /** End Organizational Strength */

    /** List of Major Customers */
    formData.append("major_customers[customer_1]", customer_1);
    formData.append(
      "major_customers[customer_1_description]",
      customer_1_description
    );
    formData.append(
      "major_customers[customer_1_bill_2010_11]",
      customer_1_bill_2010_11
    );

    formData.append("major_customers[customer_2]", customer_2);
    formData.append(
      "major_customers[customer_2_description]",
      customer_2_description
    );
    formData.append(
      "major_customers[customer_2_bill_2010_11]",
      customer_2_bill_2010_11
    );

    formData.append("major_customers[customer_3]", customer_3);
    formData.append(
      "major_customers[customer_3_description]",
      customer_3_description
    );
    formData.append(
      "major_customers[customer_3_bill_2010_11]",
      customer_3_bill_2010_11
    );

    formData.append("major_customers[customer_4]", customer_4);
    formData.append(
      "major_customers[customer_4_description]",
      customer_4_description
    );
    formData.append(
      "major_customers[customer_4_bill_2010_11]",
      customer_4_bill_2010_11
    );

    formData.append("major_customers[customer_5]", customer_5);
    formData.append(
      "major_customers[customer_5_description]",
      customer_5_description
    );
    formData.append(
      "major_customers[customer_5_bill_2010_11]",
      customer_5_bill_2010_11
    );
    /** End List of Major Customers */

    /** Quality System Particulars */
    formData.append(
      "quality_system_particulars[is_company_iso_accredited]",
      is_company_iso_accredited
    );
    formData.append("quality_system_particulars[iso_doc]", iso_doc);
    formData.append(
      "quality_system_particulars[is_company_have_other_accredited]",
      is_company_have_other_accredited
    );
    formData.append("quality_system_particulars[annexure_doc]", annexure_doc);
    /** End Quality System Particulars */

    /** Documents to be enclosed */
    formData.append("documents_to_be_enclosed[isPanCardDoc]", isPanCardDoc);
    formData.append(
      "documents_to_be_enclosed[isGst_registration_certificate_doc]",
      isGst_registration_certificate_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_incorporations_doc]",
      is_incorporations_doc
    );
    formData.append(
      "documents_to_be_enclosed[incorporations_doc]",
      incorporations_doc
    );

    formData.append("documents_to_be_enclosed[is_msme_doc]", is_msme_doc);
    formData.append("documents_to_be_enclosed[msme_doc]", msme_doc);

    formData.append("documents_to_be_enclosed[is_roc_doc]", is_roc_doc);
    formData.append("documents_to_be_enclosed[roc_doc]", roc_doc);

    formData.append(
      "documents_to_be_enclosed[is_shareholding_pattern_doc]",
      is_shareholding_pattern_doc
    );
    formData.append(
      "documents_to_be_enclosed[shareholding_pattern_doc]",
      shareholding_pattern_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_latest_telephone_bill_doc]",
      is_latest_telephone_bill_doc
    );
    formData.append(
      "documents_to_be_enclosed[latest_telephone_bill_doc]",
      latest_telephone_bill_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_any_utility_bill_doc]",
      is_any_utility_bill_doc
    );
    formData.append(
      "documents_to_be_enclosed[any_utility_bill_doc]",
      any_utility_bill_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_latest_bank_statement_doc]",
      is_latest_bank_statement_doc
    );
    formData.append(
      "documents_to_be_enclosed[latest_bank_statement_doc]",
      latest_bank_statement_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_income_sales_service_tax_doc]",
      is_income_sales_service_tax_doc
    );
    formData.append(
      "documents_to_be_enclosed[income_sales_service_tax_doc]",
      income_sales_service_tax_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_audited_balance_sheet_doc]",
      is_audited_balance_sheet_doc
    );
    formData.append(
      "documents_to_be_enclosed[audited_balance_sheet_doc]",
      audited_balance_sheet_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_networth_certificate_by_CA_doc]",
      is_networth_certificate_by_CA_doc
    );
    formData.append(
      "documents_to_be_enclosed[networth_certificate_by_CA_doc]",
      networth_certificate_by_CA_doc
    );

    formData.append(
      "documents_to_be_enclosed[is_latest_order_book_doc]",
      is_latest_order_book_doc
    );
    formData.append(
      "documents_to_be_enclosed[latest_order_book_doc]",
      latest_order_book_doc
    );

    /** End Documents to be enclosed */

    // ### End Set Form Data Keys and Values

    const response = await fetch(
      "https://backend.ayanapower.com/api/vendor-registration",
      {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
        },
        body: formData,
      }
    );

    const data = await response.json();
    console.log(response);

    if (data.status === 201) {
      navigate("/thankyou");
    }
    else if(data.status ===400){
      setIsLoading(false);
       alert("please enter mandatary field");

    }
    else{
      alert("something went wrong")
    }
  }

  return (
    <div className="vendor-form">
      <Navbar />
      <section
        className="position-relative banner_image_overlay pb-0"
        id={"vendor-form"}
      >
        <img src={vendor_banner} alt="" className="img-fluid w-100" />
      </section>
      <section className="vpgform">
        <div className="container">
          <div className="form-heading mt-3">
            <div class="title pt-4">
              <h4 className="m-auto">Supplier Registration Form</h4>
            </div>
            {/* <h1>Vendor Registration Form</h1> */}
            <p className="mt-2">
              Fill form or mail us at{" "}
              <a href="supplier@ayanapower.com" className="text-dark fw-bold">
                supplier@ayanapower.com
              </a>
              &nbsp;enclosing your credentials and areas in which you would like
              to work with us.
            </p>
          </div>
          <div id="vr_form">
            <form
              className="row g-3 justify-content-between mt-3"
              onSubmit={registerUser}
            >
              {/* Company Information */}
              <div className="col-md-12">
                <h2>Company Information</h2>
              </div>
              <div className="col-md-6">
                <input
                  value={company_name}
                  onChange={(e) => setCompanyName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Name of Company"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="City"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={postal_code}
                  onChange={(e) => setPostalCode(e.target.value)}
                  type="number"
                  className="form-control"
                  inputMode="numeric"
                  placeholder="Postal Code"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={state}
                  onChange={(e) => setStateJ(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="State"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Country"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  type="tel"
                  className="form-control"
                  placeholder="Phone"
                  maxLength="10"
                  pattern="\d{10}"
                  required
                />
              </div>
              {/* <div className="col-md-6">
                <input
                  value={fax_number1}
                  onChange={(e) => setFaxNumber1(e.target.value)}
                  type="text"
                  className="form-control"
                  inputMode="numeric"
                  placeholder="Fax No. 1"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={fax_number2}
                  onChange={(e) => setFaxNumber2(e.target.value)}
                  type="text"
                  className="form-control"
                  inputMode="numeric"
                  placeholder="Fax No. 2"
                  required
                />
              </div> */}
              <div className="col-md-6">
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={contact_person_name}
                  onChange={(e) => setContactPersonName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Contact Person Name"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  type="url"
                  className="form-control"
                  placeholder="Website"
                  required
                />
              </div>
              {/* End Company Information */}

              {/* Ownership Details */}
              <div className="col-md-12 space-40">
                <h2>Ownership Details</h2>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Management Team (MD / CEO / Partners / Proprietor)"
                  value={management_team}
                  onChange={(e) => setManagementTeam(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Names of Directors in the Company"
                  value={directors_name}
                  onChange={(e) => setDirectorsName(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Shareholding (provide information on corporate structure/ownership details)"
                  value={shareholding}
                  onChange={(e) => setShareholding(e.target.value)}
                />
              </div>
              <div className="col-md-12">
                <p>
                  Does the company have any Shareholders or Directors who are
                  Politicians/Bureaucrats / Public officials?
                </p>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="yes"
                    onChange={(e) =>
                      setIsPoliticiansOrBureaucratsOrPublicOfficials(
                        e.target.value
                      )
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio1">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="no"
                    onChange={(e) =>
                      setIsPoliticiansOrBureaucratsOrPublicOfficials(
                        e.target.value
                      )
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio2">
                    No
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  className="form-control"
                  placeholder="( If yes, kindly provide details )"
                  value={politicians_or_bureaucrats_or_public_officials_details}
                  onChange={(e) =>
                    setPoliticiansOrBureaucratsOrPublicOfficialsDetails(
                      e.target.value
                    )
                  }
                />
              </div>

              {/* End Ownership Details */}

              {/* Vendor Type */}

              <div className="col-md-12 space-40">
                <h2>Vendor Type (Please Select)</h2>
              </div>
              <div className="col-md-3">
                <div className="form-check form-check-inline">
                  <input
                    value="Service"
                    onChange={(e) => setVendorType(e.target.value)}
                    className="form-check-input"
                    type="radio"
                    required
                    name="vendor_type"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio4">
                    Service
                  </label>
                </div>
                <p>MOA & AOA (Applicable/ Not Applicable)</p>
              </div>
              <div className="col-md-6">
                <div className="form-check form-check-inline">
                  <input
                    value="yes"
                    onChange={(e) => setIsMaterials(e.target.value)}
                    className="form-check-input"
                    type="radio"
                    required
                    name="vendor_type"
                  />
                  <label className="form-check-label" htmlFor="inlineRadio3">
                    Materials
                  </label>
                </div>
                <p>
                  If Applicable: Attach a certified copy along with a
                  certificate of incorporation
                </p>
              </div>
              <div className="col-md-3">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      onChange={(e) =>
                        setVendorTypeCertifiedDoc(e.target.files[0])
                      }
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  {/* <p> Upload </p> */}
                  {vendor_type_certified_doc == null ? (
                    <p> Upload </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>
              {/* End Vendor Type */}

              {/* Statutory Requirement */}

              <div className="col-md-12 space-40">
                <h2>Statutory Requirement</h2>
              </div>
              <div className="col-md-6">
                <input
                  value={pan_number}
                  onChange={(e) => setPanNumber(e.target.value)}
                  type="text"
                  maxLength={"10"}
                  className="form-control"
                  placeholder="PAN Number *"
                  required
                />
              </div>

              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setPanDoc(e.target.files[0])}
                      id="formFile"
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> Upload your Pan Card </p>
                  {pan_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <input
                  value={is_libale}
                  onChange={(e) => setIsLiable(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Whether Liable to TDS (YES /NO) *"
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      onChange={(e) => setTdsDoc(e.target.files[0])}
                      id="formFile"
                    />

                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> Upload if YES for TDS </p>
                  {tds_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <input
                  value={tds_category}
                  onChange={(e) => setTdsCategory(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="TDS Category *"
                  required
                />
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <input
                  value={rate_of_tds}
                  onChange={(e) => setRateOfTds(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Rate of TDS *"
                />
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <input
                  value={gst_number}
                  onChange={(e) => setGstNumber(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="GST No *"
                  required
                />
              </div>
              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      onChange={(e) => setGstDoc(e.target.files[0])}
                    />

                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p>
                    Upload GST Registration Certificate if Supply of Material{" "}
                  </p>

                  {gst_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <input
                  value={ssl_regn_number}
                  onChange={(e) => setSslRegnNumber(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="MSME / SSI Regn No."
                  required
                />
                {/* <p>
                  Whether falls under the category of MSME / SSI (Medium/Small
                  Scale Industries)
                </p> */}
              </div>
              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      onChange={(e) => setGstDoc(e.target.files[0])}
                    />

                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p>Upload MSME Certificate</p>

                  {gst_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>

              {/* End Statutory Requirement */}

              {/* Bank Details */}

              <div className="col-md-12 space-40">
                <h2>Bank Details</h2>
              </div>
              <div className="col-md-6">
                <input
                  value={bank_name}
                  onChange={(e) => setBankName(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Bank Name"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Branch"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={bank_account_number}
                  onChange={(e) => setBankAccountNumber(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder="Bank Account No."
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={ifsc_code}
                  onChange={(e) => setIfscCode(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="IFSC CODE"
                  required
                />
              </div>
              <div className="col-md-12">
                <input
                  value={bank_address}
                  onChange={(e) => setBankAddress(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={account_type}
                  onChange={(e) => setAccountType(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="Bank Account Type"
                  required
                />
              </div>
              <div className="col-md-6">
                <input
                  value={micr_code}
                  onChange={(e) => setMicrCode(e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder="MICR CODE"
                  required
                />
              </div>
              <div className="col-lg-6">
                <div className="row px-3">
                  <div className="col-md-5 yearview">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          onChange={(e) =>
                            setTurnover_2018_19_doc(e.target.files[0])
                          }
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {turnover_2018_19_doc == null ? (
                        <p> Upload Cancelled Cheque </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* End Bank Details */}

              {/* Financial Information */}

              <div className="col-md-12 space-40">
                <h2>
                  Financial Information(Kindly Provide the last 3 Years IT
                  Returns & Balance Sheet)
                </h2>
              </div>
              <div className="col-md-2">
                <span className="cc_cc">Turnover:</span>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2018-19</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          onChange={(e) =>
                            setTurnover_2018_19_doc(e.target.files[0])
                          }
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {turnover_2018_19_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2019-20</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          onChange={(e) =>
                            setTurnover_2019_20_doc(e.target.files[0])
                          }
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {turnover_2019_20_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2020-21</div>
                  <div className="col-6">
                    {" "}
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          onChange={(e) =>
                            setTurnover_2020_21_doc(e.target.files[0])
                          }
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {turnover_2020_21_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <span className="cc_cc">IT Return:</span>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2018-19</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          onChange={(e) =>
                            setItReturn_2018_19_doc(e.target.files[0])
                          }
                        />
                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {it_return_2018_19_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2019-20</div>
                  <div className="col-6">
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          onChange={(e) =>
                            setItReturn_2019_20_doc(e.target.files[0])
                          }
                        />

                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {it_return_2019_20_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row yearview">
                  <div className="col-6">2020-21</div>
                  <div className="col-6">
                    {" "}
                    <div className="upload">
                      <label className="upload-area">
                        <input
                          type="file"
                          id="formFile"
                          onChange={(e) =>
                            setItReturn_2020_21_doc(e.target.files[0])
                          }
                        />

                        <span className="upload-button">
                          <img
                            className="img-fluid"
                            src={upload}
                            alt="upload"
                          />
                        </span>
                      </label>
                      {it_return_2020_21_doc == null ? (
                        <p> Upload </p>
                      ) : (
                        <p class="text-success px-2"> uploaded successfully </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <input
                  type="text"
                  value={networth}
                  className="form-control"
                  placeholder="Networth Value Attested by Chartered Accountant"
                  onChange={(e) => setNetworth(e.target.value)}
                />
              </div>

              {/* End Financial Information */}

              {/* Organizational Strength */}

              <div className="col-md-12 space-40">
                <h2>Organizational Strength</h2>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Managerial Staff"
                  value={managerial_staff}
                  onChange={(e) => setManagerialStaff(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Supervisory Staff"
                  value={supervisory_staff}
                  onChange={(e) => setSupervisoryStaff(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Skilled Labour"
                  value={skilled_labour}
                  onChange={(e) => setSkilledLabour(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Unskilled Labour"
                  value={unskilled_labour}
                  onChange={(e) => setUnskilledLabour(e.target.value)}
                />
              </div>

              {/* End Organizational Strength */}

              {/* List of Major Customers */}
              <div className="col-md-12 space-40">
                <h2>List of Major Customers (Top 5)</h2>
              </div>
              <div className="col-md-12 table-responsive">
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th>Sl.No:</th>
                      <th>Customers</th>
                      <th>Description of Work Done & Capacity</th>
                      <th>
                        UTotal billing in the last financial year(2010 -11)(Rs.)
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Customers"
                          value={customer_1}
                          onChange={(e) => setCustomer_1(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Description of Work Done & Capacity"
                          value={customer_1_description}
                          onChange={(e) =>
                            setCustomer_1_description(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="UTotal billing in the last financial year(2010 -11)(Rs.)"
                          value={customer_1_bill_2010_11}
                          onChange={(e) =>
                            setCustomer_1_bill_2010_11(e.target.value)
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>2</td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Customers"
                          value={customer_2}
                          onChange={(e) => setCustomer_2(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Description of Work Done & Capacity"
                          value={customer_2_description}
                          onChange={(e) =>
                            setCustomer_2_description(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="UTotal billing in the last financial year(2010 -11)(Rs.)"
                          value={customer_2_bill_2010_11}
                          onChange={(e) =>
                            setCustomer_2_bill_2010_11(e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Customers"
                          value={customer_3}
                          onChange={(e) => setCustomer_3(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Description of Work Done & Capacity"
                          value={customer_3_description}
                          onChange={(e) =>
                            setCustomer_3_description(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="UTotal billing in the last financial year(2010 -11)(Rs.)"
                          value={customer_3_bill_2010_11}
                          onChange={(e) =>
                            setCustomer_3_bill_2010_11(e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Customers"
                          value={customer_4}
                          onChange={(e) => setCustomer_4(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Description of Work Done & Capacity"
                          value={customer_4_description}
                          onChange={(e) =>
                            setCustomer_4_description(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="UTotal billing in the last financial year(2010 -11)(Rs.)"
                          value={customer_4_bill_2010_11}
                          onChange={(e) =>
                            setCustomer_4_bill_2010_11(e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Customers"
                          value={customer_5}
                          onChange={(e) => setCustomer_5(e.target.value)}
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="Description of Work Done & Capacity"
                          value={customer_5_description}
                          onChange={(e) =>
                            setCustomer_5_description(e.target.value)
                          }
                        />
                      </td>
                      <td>
                        <input
                          className="form-control-lg w-100"
                          type="text"
                          // placeholder="UTotal billing in the last financial year(2010 -11)(Rs.)"
                          value={customer_5_bill_2010_11}
                          onChange={(e) =>
                            setCustomer_5_bill_2010_11(e.target.value)
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* End List of Major Customers */}

              {/* Quality System Particulars */}

              <div className="col-md-12 space-40">
                <h2>Quality System Particulars</h2>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Is the Company ISO 9000/ ISO 14000 Accredited?"
                  onChange={(e) => setIsCompanyIsoAccredited(e.target.value)}
                  value={is_company_iso_accredited}
                />
              </div>
              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      onChange={(e) => setIsoDoc(e.target.files[0])}
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> (if yes, enclose a copy of the certificates)</p>
                  {iso_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptionsyes"
                    id="inlineRadio0"
                    value="yes"
                    onChange={(e) =>
                      setIsCompanyHaveOtherAccredited(e.target.value)
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio0">
                    Yes
                  </label>
                </div>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptionsyes"
                    id="inlineRadio00"
                    value="no"
                    onChange={(e) =>
                      setIsCompanyHaveOtherAccredited(e.target.value)
                    }
                  />
                  <label className="form-check-label" htmlFor="inlineRadio00">
                    No (Please specify if any other accreditation was obtained)
                  </label>
                </div>
                <span className="note">
                  <b>*Note :</b> Please attach separate Annexure sheets for of
                  List of equipment available and a List of Testing Equipment
                  available*
                </span>
              </div>
              <div className="col-md-6">
                <div className="upload">
                  <label className="upload-area">
                    <input
                      type="file"
                      id="formFile"
                      onChange={(e) => setAnnexureDoc(e.target.files[0])}
                    />
                    <span className="upload-button">
                      <img className="img-fluid" src={upload} alt="upload" />
                    </span>
                  </label>
                  <p> Upload Annexure sheets </p>
                  {annexure_doc == null ? (
                    <p> </p>
                  ) : (
                    <p class="text-success px-2"> uploaded successfully </p>
                  )}
                </div>
              </div>

              {/* End Quality System Particulars */}

              {/* Documents to be enclosed */}

              <div className="col-md-12 space-40">
                <h2>Documents to be enclosed</h2>
              </div>
              <div className="col-md-12 table-responsive">
                <table className="table  mobile-view">
                  <tbody>
                    <tr>
                      <td>PAN Card Copy :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            value="yes"
                            onChange={(e) => setIsPanCardDoc(e.target.value)}
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsvrf1"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            value="no"
                            onChange={(e) => setIsPanCardDoc(e.target.value)}
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsvrf1"
                            id="vrf2"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>GST Registration Certificate :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            value="yes"
                            onChange={(e) =>
                              setIsGstRegistrationCertificateDoc(e.target.value)
                            }
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsvrf2"
                            id="vrf1"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            value="no"
                            onChange={(e) =>
                              setIsGstRegistrationCertificateDoc(e.target.value)
                            }
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsvrf2"
                            id="vrf2"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td> </td>
                    </tr>
                    <tr>
                      <td>
                        MOA & AOA (certified copy along with the certificate of
                        incorporations) :
                      </td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            value="yes"
                            onChange={(e) =>
                              setIsIncorporationsDoc(e.target.value)
                            }
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsvrf3"
                            id="vrf1"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            value="no"
                            onChange={(e) =>
                              setIsIncorporationsDoc(e.target.value)
                            }
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptionsvrf3"
                            id="vrf2"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setIncorporationsDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {incorporations_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>MSME Registration Certificate :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) => setIsMsmeDoc(e.target.value)}
                            value="yes"
                            name="inlineRadioOptionsvrf4"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) => setIsMsmeDoc(e.target.value)}
                            value="no"
                            name="inlineRadioOptionsvrf4"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) => setMsmeDoc(e.target.files[0])}
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {msme_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>ROC :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) => setIsRocDoc(e.target.value)}
                            value="yes"
                            name="inlineRadioOptionsvrf5"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) => setIsRocDoc(e.target.value)}
                            value="no"
                            name="inlineRadioOptionsvrf5"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) => setRocDoc(e.target.files[0])}
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {roc_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Supporting Document of Company Shareholding Pattern :
                      </td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsShareholdingPatternDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf6"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsShareholdingPatternDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf6"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setShareholdingPatternDoc(e.target.files[0])
                              }
                            />

                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {shareholding_pattern_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Latest telephone (landline) bill (or) :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsLatestTelephoneBillDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf7"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsLatestTelephoneBillDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf7"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setLatestTelephoneBillDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {latest_telephone_bill_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Any utility bill in the name of the entity (or) :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsAnyUtilityBillDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf8"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsAnyUtilityBillDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf8"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setAnyUtilityBillDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {any_utility_bill_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Latest bank statement. (Proof of address) :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsLatestBankStatementDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf9"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsLatestBankStatementDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf9"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setLatestBankStatementDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {latest_bank_statement_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Income tax, sales tax, and service tax assessment orders
                        :
                      </td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsIncomeSalesServiceTaxDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf10"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsIncomeSalesServiceTaxDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf10"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setIncomeSalesServiceTaxDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {income_sales_service_tax_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Audited balance sheet :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsAuditedBalanceSheetDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf11"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsAuditedBalanceSheetDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf11"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setAuditedBalanceSheetDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {audited_balance_sheet_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Networth certificate by CA :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsNetworthCertificateByCaDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf12"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsNetworthCertificateByCaDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf12"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setNetworthCertificateByCaDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="upload"
                              />
                            </span>
                          </label>
                          {networth_certificate_by_CA_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Latest order book :</td>
                      <td className="d-flex">
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsLatestOrderBookDoc(e.target.value)
                            }
                            value="yes"
                            name="inlineRadioOptionsvrf13"
                          />
                          <label className="form-check-label" htmlFor="vrf1">
                            Yes
                          </label>
                        </div>
                        <div className="form-check form-check-inline d-flex">
                          <input
                            className="form-check-input"
                            type="radio"
                            onChange={(e) =>
                              setIsLatestOrderBookDoc(e.target.value)
                            }
                            value="no"
                            name="inlineRadioOptionsvrf13"
                          />
                          <label className="form-check-label" htmlFor="vrf2">
                            No
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className="upload">
                          <label className="upload-area">
                            <input
                              type="file"
                              id="formFile"
                              onChange={(e) =>
                                setLatestOrderBookDoc(e.target.files[0])
                              }
                            />
                            <span className="upload-button">
                              <img
                                className="img-fluid"
                                src={upload}
                                alt="form-img"
                              />
                            </span>
                          </label>

                          {latest_order_book_doc == null ? (
                            <p> Upload </p>
                          ) : (
                            <p class="text-success px-2">
                              {" "}
                              uploaded successfully{" "}
                            </p>
                          )}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* <p>( Doc No: ARPPL-SCM-004 )</p> */}
              </div>

              {/* End Documents to be enclosed */}
              <div className="preloadVenContainer">
                <div className=" preloaderbtnVendor">
                  {isLoading && (
                    <div className="preloaderbtnSpinVendor">
                      <PreloaderForm />
                    </div>
                  )}
                  <input
                    type="submit"
                    value="submit"
                    className="vendorSubmitButton mb-3"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default VendorForm;
