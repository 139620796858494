import React from "react";
import "./fun-at-work-slider.scss";

import Fun01 from "../../assets/img/ayana_life_main/Fun01.jpg";
// import life4 from "../../assets/img/ayana_life_main/life4.jpg";
import HomeLifeAyana01 from "../../assets/img/HomeLogoSliderImages/fun1.png";
import HomeLifeAyana02 from "../../assets/img/HomeLogoSliderImages/fun2.png";
import HomeLifeAyana03 from "../../assets/img/HomeLogoSliderImages/fun3.png";
import HomeLifeAyana04 from "../../assets/img/HomeLogoSliderImages/fun4.png";
import HomeLifeAyana05 from "../../assets/img/HomeLogoSliderImages/fun5.png";
import HomeLifeAyana06 from "../../assets/img/HomeLogoSliderImages/fun6.png";
import HomeLifeAyana07 from "../../assets/img/HomeLogoSliderImages/fun7.png";
// import HomeLifeAyana08 from "../../assets/img/HomeLogoSliderImages/fun8.JPG";
// import HomeLifeAyana09 from "../../assets/img/HomeLogoSliderImages/fun9.JPG";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  xldesktop: {
    breakpoint: { max: 2526, min: 1920 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const FunatWorkSlider = () => {
  return (
    <div className="">
      <Carousel
        autoPlay={true}
        infinite
        responsive={responsive}
        itemClass="p-2"
      >
        <img className="w-100" src={Fun01} alt="arrow" />
        {/* <img className="w-100" src={life4} alt="arrow" /> */}
        <img className="w-100" src={HomeLifeAyana01} alt="arrow" />
        <img className="w-100" src={HomeLifeAyana02} alt="arrow" />
        <img className="w-100" src={HomeLifeAyana03} alt="card" />
        <img className="w-100" src={HomeLifeAyana04} alt="arrow" />
        <img className="w-100" src={HomeLifeAyana05} alt="arrow" />
        <img className="w-100" src={HomeLifeAyana06} alt="arrow" />
        <img className="w-100" src={HomeLifeAyana07} alt="arrow" />
        {/* <img className="w-100" src={HomeLifeAyana08} alt="arrow" />
        <img className="w-100" src={HomeLifeAyana09} alt="arrow" /> */}
      </Carousel>
    </div>
  );
};

export default FunatWorkSlider;
