import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import parse from "html-react-parser";
import Footer from "../../components/Footer/Footer";
import ReadMoreImg from "../../assets/img/blogs/readMoreImg.jpg";
import Instagram from "../../assets/img/blogs/instagram.svg";
// import Youtube from "../../assets/img/blogs/youtube.svg";
import LinkedIn from "../../assets/img/blogs/linkedin.svg";
import Facebook from "../../assets/img/blogs/facebook.svg";
import Twitter from "../../assets/img/blogs/twitter.svg";
import blog_banner from "../../assets/img/blogs/blog_banner.jpg";
import React, { useState, useEffect } from "react";
import moment from "moment";
import config from "../../config";

import "./blog-detail.scss";
import Navbar from "../../components/Navbar/Navbar";
import Spinner from "../../components/Spinner/Spinner";

const BlogDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const uploadUrl = "https://backend.ayanapower.com/storage/blog/";
  // blog detials fectch

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [blogData, setBlog] = useState([]);
  const [next, setNext] = useState("");

  useEffect(() => {
    let slug = id;
    if (next !== "") {
      slug = next;
    }

    fetch(`${config.base_url}get-blog/${slug}`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setBlog(result.blog);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [next]);

  // !blog details fectch

  var url = window.location.href;
  // Share on Facebook
  function shareOnfacebook() {
    // Replace with your actual URL to be shared
    var facebookUrl =
      "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url);
    window.open(facebookUrl);
  }
  function shareOntwitter() {
    var twitterUrl =
      "https://twitter.com/intent/tweet?url=" + encodeURIComponent(url);
    window.open(twitterUrl);
  }
  function shareOnlinked() {
    var linkedInUrl =
      "https://www.linkedin.com/sharing/share-offsite/?url=" +
      encodeURIComponent(url);
    window.open(linkedInUrl);
  }
  function shareOninstagram() {
    const instagramUrl =
      "https://www.instagram.com/?url=" + encodeURIComponent(url);
    window.open(instagramUrl);
  }
  // function shareOnyoutub() {
  //   const youtubeUrl =
  //     "https://www.youtube.com/watch?v=" + encodeURIComponent(url);
  //   window.open(youtubeUrl);
  // }
  //
  // form submit
  const [message, setMessage] = useState();
  const maxLength = 300;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    msg: "",
    blog_id: "",
  });
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      formData.msg = inputValue;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formData.blog_id = isLoaded ? blogData.id : 0;
    // Send the form data to the API
    sendData(formData);
  };

  const sendData = async (data) => {
    try {
      const response = await fetch(`${config.base_url}blog-comment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          msg: "",
        });
        // Success
        setMessage(
          <div className="alert alert-success" role="alert">
            your comment is received !
          </div>
        );
        document.getElementById("popup").style.display = "block";
      } else {
        // Error
        console.error("Error sending form data.");
      }
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };
  // popup msg
  function closePopup() {
    document.getElementById("popup").style.display = "none";
  }
  // end form submit
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div>
        <Navbar />
        <div id="blog-detail">
          <div className="blogDetailsContainer">
            <section
              className="pt-0 pb-0 position-relative banner_image_overlay"
              id={"blog"}
            >
              <img src={blog_banner} alt="" className="img-fluid w-100" />
              <div className="breadcrumb_text">
                <h4 className="breadcrumb_title">Blogs</h4>
                <p className="breadcrumb_link">
                  <Link to="/"> Home</Link> | Blogs
                </p>
              </div>
            </section>

            <section style={{ paddingLeft: "50px", paddingRight: "50px" }}>
              <div className="goBackButtonContainer">
                <button onClick={() => navigate("/blogs")}>Go back</button>
              </div>
              <div className="blogcontainer ">
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-12 col-xl-8">
                    <>
                      <h2>{blogData.title}</h2>
                      <p>{parse(blogData.paragraph)}</p>
                    </>
                    <img
                      src={`${uploadUrl}${blogData.image}`}
                      alt="blog detail"
                    />
                    <div className="mt-2">
                      <h4>Submit a Comment</h4>
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-12 py-2">
                            <p>
                              Characters remaining:{" "}
                              {maxLength - formData.msg.length}/{maxLength}
                            </p>
                            <textarea
                              rows="5"
                              className="form-control"
                              onChange={handleChange}
                              maxLength={maxLength}
                              value={formData.msg}
                              placeholder="Leave Comment ....."
                              name="msg"
                              id=""
                              required
                            />
                          </div>
                          <div className="col-md-12 py-2">
                            <input
                              className="form-control"
                              name="name"
                              onChange={handleChange}
                              value={formData.name}
                              placeholder="Name"
                              type="text"
                              required
                            />
                          </div>
                          <div className="col-md-12 py-2">
                            <input
                              className="form-control"
                              placeholder="Email"
                              onChange={handleChange}
                              value={formData.email}
                              type="email"
                              name="email"
                              id=""
                              required
                            />
                            <input
                              type="hidden"
                              name="blog_id"
                              value={blogData.id}
                            />
                          </div>
                          <div className="col-md-12 py-2">
                            <button className="btn btn-info" type="submit">
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* comments list */}
                    <div className="mt-2">
                      <h4>Comments</h4>
                      <div className="py-2 mb-3">
                        {blogData.comments?.map((comment) => (
                          <>
                            <h5 className="m-0 pt-2">{comment.name}</h5>
                            <p className="" style={{ fontSize: "12px" }}>
                              Posted at :{" "}
                              {moment(comment.created_at).format(
                                "DD MMM, YYYY"
                              )}
                            </p>
                            <p className="border-bottom mt-2 border-1">
                              {comment.msg}
                            </p>
                          </>
                        ))}
                      </div>
                    </div>
                    {/* end comments list */}
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-xl-4">
                    <div className="d-flex align-items-center pb-4 placeholderContainer">
                      <input type="text" placeholder="Search Blogs" />
                      <div className="searchIcon">
                        <SearchIcon />
                      </div>
                    </div>
                    <div className="readNextContainer p-4 w-100">
                      {blogData.next ? (
                        <span onClick={(e) => setNext(blogData.next.slug)}>
                          <h2>Read Next Blog</h2>
                          <img
                            src={`${uploadUrl}${blogData.next.image}`}
                            alt=""
                          />
                          <p className="pt-2">{blogData.next.title}</p>
                        </span>
                      ) : (
                        <h6>Coming new blog soon</h6>
                      )}
                    </div>
                    <div className="pt-4 mb-4">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-xl-12">
                          <div className=" shareContainer p-4 w-100">
                            <h2>Share ON:</h2>
                            <div className="d-flex gap-4 align-items-center mb-2">
                              <button
                                className="btn"
                                onClick={shareOninstagram}
                              >
                                <img src={Instagram} alt="instagram" />
                              </button>
                              <span>Instagram</span>
                            </div>
                            <div className="d-flex gap-4 align-items-center mb-2">
                              <button onClick={shareOnlinked} className="btn">
                                <img src={LinkedIn} alt="LinkedIn" />
                              </button>
                              <span>LinkedIn</span>
                            </div>
                            {/* <div className="d-flex gap-4 align-items-center mb-2">
                              <button onClick={shareOnlinked} className="btn">
                                <img src={LinkedIn} alt="linkedin" />
                              </button>
                              ​<span>LinkedIn</span>
                            </div> */}
                            <div className="d-flex gap-4 align-items-center mb-2">
                              <button onClick={shareOnfacebook} className="btn">
                                <img src={Facebook} alt="Facebook" />
                              </button>
                              <span>Facebook</span>
                            </div>
                            <div className="d-flex gap-4 align-items-center">
                              <button onClick={shareOntwitter} className="btn">
                                <img src={Twitter} alt="Twitter" />
                              </button>
                              <span>Twitter</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="popup" id="popup">
                <div className="popup-content ">
                  <span className="close bg-light" onClick={closePopup}>
                    &times;
                  </span>
                  <p className="p-2 text-success">
                    We have received your comment and it will be reflected soon
                  </p>
                </div>
              </div>
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
};

export default BlogDetail;
