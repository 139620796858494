import Amit_Agarwal from "../../assets/img/employee_stories/amit.png";
// import Atul_Ranjan from "../../assets/Atul-Ranjan.png";
// import Apurva_Singh from "../../assets/Apurva-Singh.png";
import Piyush_Srivastav from "../../assets/img/employee_stories/piyush.png";
import akshat_rastogi from "../../assets/img/employee_stories/akshat_rastogi.png";
import syed_nawaz from "../../assets/img/employee_stories/syed_nawaz.png";
import sowmya from "../../assets/img/employee_stories/sowmya.png";
import srinivas from "../../assets/img/employee_stories/srinivas.png";
import shinam from "../../assets/img/employee_stories/shinam.png";
import rajesh from "../../assets/img/employee_stories/rajesh.png";

const people = [
  {
    id: 1,
    image: Amit_Agarwal,
    name: "~ Amit Agarwal",
    description: "Asst. Vice President & Head Solar - Projects",
    quote:
      "Ayana is a renewable energy company that has rapidly grown from developing one solar project to multiple renewable energy projects on a gigawatt scale, including solar, wind, hybrid, and hydrogen. The leadership at Ayana is passionate about developing large, green projects and the company's culture of freedom and teamwork allows employees to excel. Ayana has a clear vision and mission, and a culture of teamwork that helps support its goal of achieving green energy goals.",
  },
  {
    id: 2,
    image: akshat_rastogi,
    name: "~ Akshat Rastogi",
    description: "Sr. Manager - Projects",
    quote:
      "I am grateful for the chance to be a part of Ayana, a company with an extensive project portfolio that requires efficient project management. In my role as a project manager, it is crucial to handle these projects effectively. I am fortunate to have the support of cross-functional teams who are always ready to assist, and the leadership is accessible and approachable. Ayana fosters an inclusive environment, where I feel involved in every company event, and I appreciate the transparency and openness of our work culture. I am excited about the promising journey ahead and eager to grow alongside Ayana.",
  },
  {
    id: 3,
    image: syed_nawaz,
    name: "~ Syed Khaja Nawaz",
    description: "Asst. General Manager - Legal",
    quote:
      "I want to express my heartfelt appreciation to Team Ayana, as the Company has been consistently fixed to commitment on legal, ethical, and strict processes in order to strengthen the company's interests and upheld its reputation. I and our legal team are fixed to this commitment as to succeed in all milestones where Ayana steps-in.",
  },
  {
    id: 4,
    image: Piyush_Srivastav,
    name: "~ Piyush Srivastav",
    description: "Assistant General Manager - Data Analytics",
    quote:
      "I’m a proud member of team Ayana and excited to contribute to the organisational “Digitalisation of Renewable Assets” journey. Ayana is building a world-class excellence centre for advanced analytics and machine learning to improve the performance of its renewable assets. The open office culture promotes agile processes and easy access to leadership, making it a great workplace in the renewable energy industry in India.",
  },
  {
    id: 5,
    image: sowmya,
    name: "~ Sowmya R",
    description: "Company Secretary",
    quote:
      "I started my Company Secretary journey at Ayana in 2018, in the corporate secretarial and compliance team, which has been a remarkable experience in terms of my professional and personal growth. Working in a Company that values attention to detail and a commitment to perfection has taught me the importance of precision in my work. Ayana has provided me with ample opportunities to deep dive into complex issues and resolve it through the guidance and mentorship of experienced professionals. Being part of a team that upholds the highest standards of ethics and integrity, has instilled in me a sense of pride and responsibility. Ayana has not only enriched my professional journey but has also nurtured my personal growth, enabling me to become a more well-rounded individual. On the personal front, Ayana has given me an identity as an individual in my personal life and I feel, Ayana is an extended family where I feel very safe.",
  },
  {
    id: 6,
    image: srinivas,
    name: "~ N.Srinivas Reddy",
    description: "Asst. General Manager - SCM (Insurance)",
    quote:
      "Being a part of the Ayana family fills me with immense pride. The company's work environment, culture, and the behaviour of my fellow employees are truly amazing, providing the ideal platform to nurture my professional career. The working culture is exceptional, and the support from higher management is truly commendable. The perfect work-life balance adds to the overall experience, allowing me to thoroughly enjoy my time here. I am fortunate to be part of one of the best SCM teams, who provide unwavering support. The days simply fly by due to the enriching cross-cultural interactions among employees. Ayana is where I thrive and continue to grow both personally and professionally.",
  },
  {
    id: 7,
    image: shinam,
    name: "~ Shinam Agarwal",
    description: "Asst. Manager - HR & Administration",
    quote:
      "At Ayana, there is a perfect blend of work and fun, creating a vibrant workplace where employees wholeheartedly support one another. Despite being here for just a few days, I already feel a strong sense of enjoyment and anticipation when coming to work, fueled by the remarkable motivation I receive from my incredible team. Being a part of Ayana fills me with joy, and I am grateful for the opportunity to contribute to the company's success.",
  },
  {
    id: 7,
    image: rajesh,
    name: "~ Rajesh Rudrappa",
    description: "Finance Controller - Finance and Accounts",
    quote:
      "Being with Ayana from the very beginning has been an incredible journey. I have witnessed first-hand the remarkable growth of this company, from its humble beginnings to its current stature of employing hundreds of talented individuals. It fills me with immense pride to have been part of this transformative process. As Ayana continues to make significant strides in the renewable energy industry, I am excited to be a part of its future endeavours. This journey has not only shaped my professional growth but has also allowed me to contribute meaningfully towards a sustainable future.",
  },
];

export default people;
