import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useImageUpload from "../../../hooks/imageUploads";
import { uploadBlog } from "../../../network/admin";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const BlogForm = ({
  id = "",
  title = "",
  createdAt = "",
  image = "",
  visibility = 1,
  metaTitle = "",
  metaDescription = "",
  metaKeywords = "",
  publishedAt = "",
  description = "",
}) => {
  const [formData, setFormData] = useState({
    id,
    title,
    createdAt,

    image,
    visibility,
    metaTitle,
    metaDescription,
    metaKeywords,
    publishedAt,
    description,
  });

  const [errors, setErrors] = useState({});
  const { imageUrl, error, handleImageChange } = useImageUpload();
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      createdAt: date,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    let obj = {
      title: formData?.title,
      publishedAt: formData?.publishedAt,
      description: formData?.description,
      image: formData?.image,
      visibility: formData?.visibility === "1" ? true : false,
      meta_details: {
        description: formData?.metaDescription,
        keywords: formData?.metaKeywords,
        title: formData?.metaTitle,
      },
    };
    try {
      const resp = await uploadBlog(obj);
      if (resp?.data) {
        toast.success(resp?.data?.message);
        setFormData((prevValues) => {
          const clearedValues = {};
          for (const key in prevValues) {
            clearedValues[key] = "";
          }
          return clearedValues;
        });
        setTimeout(() => {
          navigate(-1);
        }, [900]);
      } else {
        toast.error(resp?.message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="card">
      <ToastContainer />
      <div className="card-body py-4">
        <form
          className="form"
          id="blog-blog"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <input type="hidden" name="id" value={formData.id} />
          <input
            type="hidden"
            name="published_at"
            value={formData.publishedAt}
          />

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2 ">
            <div className="col-8">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span className="required">Title</span>
                </label>
                <input
                  type="text"
                  name="title"
                  className="form-control form-control-solid"
                  value={formData.title}
                  onChange={handleInputChange}
                />
                {errors.title && (
                  <div className="invalid-feedback">{errors.title}</div>
                )}
              </div>
            </div>
            <div className="col-4">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span className="required">Posted At</span>
                </label>{" "}
                <br />
                <DatePicker
                  selected={formData.createdAt}
                  onChange={handleDateChange}
                  className="form-control form-control-solid"
                  dateFormat="yyyy-MM-dd"
                />
                {errors.createdAt && (
                  <div className="invalid-feedback">{errors.createdAt}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span className="required">Description</span>
                </label>
                <textarea
                  className="form-control form-control-solid"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                />
                {errors.description && (
                  <div className="invalid-feedback">{errors.description}</div>
                )}
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-1 row-cols-lg-2">
            <div className="col">
              <div className="fv-row mb-7">
                <label className="fs-6 fw-bold form-label mt-3">
                  <span>Image</span>
                </label>
                <input
                  type="file"
                  name="file1"
                  accept=".jpg,.png,.gif,.jpeg,.svg"
                  onChange={async (e) => {
                    await handleImageChange(e);
                    setFormData({ ...formData, image: imageUrl });
                  }}
                />
                {imageUrl && (
                  <img className="img-fluid w-25" src={imageUrl} alt="ayana" />
                )}
                <input type="hidden" name="image" value={formData.image} />
                {errors.image && (
                  <div className="invalid-feedback">{errors.image}</div>
                )}
              </div>
            </div>
            <div className="col">
              <div className="row mb-3">
                <label className="col-form-label col-sm-3 col-12 fs-6 fw-bold mt-3">
                  <span className="required">Visibility</span>
                </label>
                <div className="col-sm-9 row">
                  <div className="form-check col-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="visibilityPublic"
                      value={1}
                      checked={formData.visibility === 1}
                      onChange={handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="visibilityPublic"
                    >
                      Public
                    </label>
                  </div>
                  <div className="form-check col-5">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="visibility"
                      id="visibilityPrivate"
                      value={2}
                      checked={formData.visibility === 2}
                      onChange={handleInputChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="visibilityPrivate"
                    >
                      Private
                    </label>
                  </div>
                  {errors.visibility && (
                    <div className="invalid-feedback d-block">
                      {errors.visibility}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />

          <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
            <li className="nav-item">
              <a
                className="nav-link active"
                data-bs-toggle="tab"
                href="#kt_tab_pane_1"
              >
                Meta Details
              </a>
            </li>
          </ul>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="kt_tab_pane_1"
              role="tabpanel"
            >
              <div className="row">
                <div className="col">
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-bold form-label mt-3">
                      <span>Meta Title</span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      name="metaTitle"
                      value={formData.metaTitle}
                      onChange={handleInputChange}
                    />
                    {errors.metaTitle && (
                      <div className="invalid-feedback">{errors.metaTitle}</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-bold form-label mt-3">
                      <span>Meta Description</span>
                    </label>
                    <textarea
                      className="form-control form-control-solid"
                      name="metaDescription"
                      value={formData.metaDescription}
                      onChange={handleInputChange}
                    />
                    {errors.metaDescription && (
                      <div className="invalid-feedback">
                        {errors.metaDescription}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="fv-row mb-7">
                    <label className="fs-6 fw-bold form-label mt-3">
                      <span>Meta Keywords</span>
                    </label>
                    <textarea
                      className="form-control form-control-solid"
                      name="metaKeywords"
                      value={formData.metaKeywords}
                      onChange={handleInputChange}
                    />
                    {errors.metaKeywords && (
                      <div className="invalid-feedback">
                        {errors.metaKeywords}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="separator mb-6"></div>
          <div className="d-flex justify-content-end">
            <a href={`/admin/blog`} className="btn btn-light me-3">
              Cancel
            </a>
            {formData.id ? (
              <button type="submit" className="btn btn-primary">
                Update
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Publish
              </button>
            )}
            <span className="indicator-progress">
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BlogForm;
