import React, { useState, useEffect } from "react";
import "./slider-team.scss";
import data from "./data-team";

const SliderTeam = () => {
  const [people] = useState(data);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 10000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <div className="section-center">
      {people.map((item, indexPeople) => {
        const { id, image, name, quote, description } = item;
        let position = "nextSlide";
        if (indexPeople === index) {
          position = "activeSlide";
        }
        if (
          indexPeople === index - 1 ||
          (index === 0 && indexPeople === people.length - 1)
        ) {
          position = "lastSlide";
        }
        return (
          <div key={id}>
            <article className={position}>
              <p className="text">{quote}</p>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <h4>{description}</h4>
            </article>

            <article className={position}>
              <p className="text">{quote}</p>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <h4>{description}</h4>
            </article>
            <article className={position}>
              <p className="text">{quote}</p>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <h4 sy>{description}</h4>
            </article>
          </div>
        );
      })}
      <button className="prev" onClick={() => setIndex(index - 1)}>
        <i className="fa-solid fa-left-long"></i>
      </button>
      <button className="next" onClick={() => setIndex(index + 1)}>
        <i className="fa-sharp fa-solid fa-right-long"></i>
      </button>
    </div>
  );
};

export default SliderTeam;
