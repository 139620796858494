export default {
  STORE_KEY: "a56z0fzrNpl^2",
  base_url: 'https://backend.ayanapower.com/api/',
  // base_url: "https://backend.ayanapower.com/api/",
  COLOR: {
    ORANGE: "#C50",
    DARKBLUE: "#0F3274",
    LIGHTBLUE: "#6EA8DA",
    DARKGRAY: "#999",
  },
};
