import React, { useEffect, useState } from "react";
import AdminLayout from "../../../components/AdminLayout";
import { isAuth, logout } from "../../../network/helper";

const Enquiry = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isAuth()) {
      logout();
    } else {
      setLoading(false);
    }
  }, []);
  return <div>Enquiry</div>;
};

export default Enquiry;
