import {React , useState , useEffect }  from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

import "./governance.scss";
import bi1 from "../../assets/img/code-of-conduct.jpg";
//import report4 from "../../assets/img/report4.png";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import goveranceImg1 from "../../assets/img/goverance_card01.svg";
import goveranceImg2 from "../../assets/img/goverance_card02.svg";
import zeroTolerance from "../../assets/img/zero-tolerance.svg";
import goveranceImg4 from "../../assets/img/goverance_card04.svg";
import highIntegrity from "../../assets/img/high-integrity.svg";
import transparency from "../../assets/img/transparency.svg";
import intoleranceNegligence from "../../assets/img/intolerance-negligence.svg";
import corporate_governance_img from "../../assets/img/corporate_governance_img.png";
import corporate_governance_banner from "../../assets/img/corporate-governance-banner.jpg";
import Board_Committees_Img from "../../assets/img/CORPORATE GOVERNANCE/Board_Committees_Img.png";
import Compliance_Framework_img from "../../assets/img/CORPORATE GOVERNANCE/Compliance_Framework_img.png";
import Noticeofextraordinarygeneral_img from "../../assets/img/CORPORATE GOVERNANCE/NoticeofExtraOrdinaryGeneral.jpg";
import CSR_Policy_Img from "../../assets/img/CORPORATE GOVERNANCE/CSR_Policy_Img.png";
import Key_Managerial_Personnel_img from "../../assets/img/CORPORATE GOVERNANCE/Key_Managerial_Personnel_img.png";
import POSH_Policy_img from "../../assets/img/CORPORATE GOVERNANCE/POSH_Policy_img.png";
import Process_for_Vendor_Due_Diligence_img from "../../assets/img/CORPORATE GOVERNANCE/Process_for_Vendor_Due_Diligence_img.png";
import Suppliers_Code_of_Conduct_img from "../../assets/img/CORPORATE GOVERNANCE/Suppliers_Code_of_Conduct_img.png";
import Sustainability_Performance_img from "../../assets/img/CORPORATE GOVERNANCE/Sustainability_Performance_img.png";
import MGT_7_2020_2021_img from "../../assets/img/CORPORATE GOVERNANCE/MGT_7_2020_2021_img.png";
import Business_Ethics_Compliance_Procedures_img from "../../assets/img/CORPORATE GOVERNANCE/Business_Ethics_Compliance_Procedures_img.png";
import MGT_7_2021_2022_img from "../../assets/img/CORPORATE GOVERNANCE/MGT_7_2021_2022_img.png";

import Board_Committees_Composition from "../../assets/pdf/CorporateGoverence/Board_and_Committee_Composition.pdf";
import Compliance_Framework from "../../assets/pdf/CorporateGoverence/Compliance_Framework.pdf";
import CSR_Policy from "../../assets/pdf/CorporateGoverence/CSR_Policy.pdf";
import Key_Managerial_Personnel from "../../assets/pdf/CorporateGoverence/Key_Managerial_Personnel.pdf";
import POSH_Policy from "../../assets/pdf/CorporateGoverence/POSH_Policy.pdf";
import Process_for_Vendor_Due_Diligence from "../../assets/pdf/CorporateGoverence/Process_for_Vendor_Due_Diligence.pdf";
import Suppliers_Code_of_Conduct from "../../assets/pdf/CorporateGoverence/Suppliers_Code_of_Conduct.pdf";
import Sustainability_Performance from "../../assets/pdf/CorporateGoverence/Sustainability_Performance.pdf";
import MGT_7_2020_2021 from "../../assets/pdf/CorporateGoverence/MGT_7_2020_2021.pdf";
import Business_Ethics_Compliance_Procedures from "../../assets/pdf/CorporateGoverence/Business_Ethics_Compliance_Procedures.pdf";
import MGT_7_2021_2022 from "../../assets/pdf/CorporateGoverence/MGT_7_2021_2022.pdf";
import NoticeOfExtraOrdinary from "../../assets/pdf/CorporateGoverence/NoticeofExtraOrdinaryGeneralMeetingNo2of2023-2024.pdf"
import AyanaCodeofConduct from "../../assets/pdf/CorporateGoverence/Ayana_Code of Conduct_Website.pdf";

import FormThumbnail from '../../assets/img/Form/Thumbnail.png'
import FormPdf from '../../assets/pdf/CorporateGoverence/Form_MGT-7_FY_2022-2023.pdf'
import Whistle_Blower_Policy from '../../assets/pdf/CorporateGoverence/Whistle_Blower_Policy.pdf'
import FormPdf_2 from '../../assets/pdf/CorporateGoverence/MGT-7_2019-20.pdf'
import Anti_Bribery_Anti_Corruption_Policy from '../../assets/pdf/CorporateGoverence/Anti_Bribery_&_Anti_Corruption_Policy.pdf'
import config from "../../config";
import Spinner from "../../components/Spinner/Spinner";

const Governance = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const uploadUrl = "https://backend.ayanapower.com/storage/sustainablility_policy/";
  useEffect(() => {
    fetch(`${config.base_url}get-governances`)
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  return (
    <div className="governance">
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id={"goverence"}
      >
        <img
          src={corporate_governance_banner}
          alt=""
          className="img-fluid w-100"
        />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">Corporate governance</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | Corporate Governance
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img src={corporate_governance_img} alt="" className="w-50" />
            </div>
            <div className="col-md-8 vision_mission_block ps-4">
              <p>
                Ayana Renewable Power is committed to conducting its business
                with integrity and safeguarding the interest of stakeholders
                against any possible misconduct. To ensure this commitment, we
                have adopted specific policies that are applicable to everyone
                in our organisation and its subsidiaries.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg_grey ">
        <div className="container">
          <div className="row text-center">
            <center>
              <div className="title pt-5">
                <h4>YardSticks</h4>
              </div>
            </center>
          </div>
          <div className="card-container px-0 px-md-4 mt-3">
            <div className="row g-4 px-2">
              <div className="col-sm-6 col-md-6 col-xl-6">
                <div className="card">
                  <img className="cardImage" src={zeroTolerance} alt="" />
                  <p className="cardHeading">
                    {/* Zero tolerance <br /> approach */}
                    Zero tolerance towards <br /> negligence
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-xl-6">
                <div className="card">
                  <img className="cardImage" src={highIntegrity} alt="" />
                  <p className="cardHeading">
                    {/* High integrity */}
                    Zero tolerance towards bribing and <br /> corruption
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-xl-6  mt-4">
                <div className="card">
                  <img className="cardImage" src={transparency} alt="" />
                  <p className="cardHeading">
                    {/* Transparency */}
                    Transparency across all <br /> operations
                  </p>
                </div>
              </div>

              <div className="col-sm-6 col-md-6 col-xl-6 mt-4">
                <div className="card">
                  <img
                    className="cardImage"
                    src={intoleranceNegligence}
                    alt=""
                  />
                  <p className="cardHeading">
                    {/* Intolerance of negligence */}
                    Gold standards of governance and <br /> integrity
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-center">
            <center>
              <div className="title pt-5">
                <h4>Outcomes</h4>
              </div>
            </center>
          </div>
          <div className="card-container px-0 px-md-4 mt-3">
            <div className="row g-4 px-2">
              <div className="col-12 col-sm-4 col-md-4 col-xl-4 mt-4">
                <div className="card">
                  <img className="cardImage" src={goveranceImg1} alt="" />
                  <p className="cardHeading">100% Compliance focused Company</p>
                </div>
              </div>

              <div className="col-12 col-sm-4 col-md-4 col-xl-4 mt-4">
                <div className="card">
                  <img className="cardImage" src={goveranceImg2} alt="" />
                  <p className="cardHeading">
                    Compliance with all applicable laws
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4 col-md-4 col-xl-4 mt-4">
                <div className="card">
                  <img className="cardImage" src={goveranceImg4} alt="" />
                  <p className="cardHeading">
                    No adverse observations recorded during the last 6 years
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <center>
        <div className="title pt-5">
          <h4>Policies</h4>
        </div>
      </center>
      <section>
        <div className="container">
          <div className="row ">

          {
            error ? (
              <div>Error: {error.message}</div>
            ):
             !isLoaded ? (
                <div>
                <Spinner />
                </div>
             ) : ( 
              items.data.map((data) => {
                  return <>
                            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
                      <div className="bi_shadow_box bg-white p-3">
                        <img src={`${uploadUrl}${data.image}`}  alt="blog" className="w-100"  />
                        <div className="row mt-3">
                          <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                            <p>{data.title}</p>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                            {data.pdf ?
                            ( <a
                              className="pdf_link"
                              href={`${uploadUrl}${data.pdf}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <PictureAsPdfIcon />
                            </a>) : '' }  
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
              })
             

             )
          }
          
          {/* <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Noticeofextraordinarygeneral_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Notice of Extra Ordinary General Meeting No. 2 of 2023-2024</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={NoticeOfExtraOrdinary}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}


{/* 
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={FormThumbnail} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Form MGT-7 FY 2022-2023</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={FormPdf}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={FormThumbnail} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Whistle Blower policy</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Whistle_Blower_Policy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={FormThumbnail} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>MGT-7 2019-20</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={FormPdf_2}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={FormThumbnail} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Anti-Bribery and Corruption Policy</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Anti_Bribery_Anti_Corruption_Policy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Board_Committees_Img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Board and Committees's Composition</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Board_Committees_Composition}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Compliance_Framework_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Management Procedure
                      For Complience</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Compliance_Framework}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={CSR_Policy_Img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Corporate Social Responsibility Policy</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={CSR_Policy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Key_Managerial_Personnel_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Key Managerial Personal Under Companies Act 2013</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Key_Managerial_Personnel}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={POSH_Policy_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Prevention of Sexual Harassment Policy
                    </p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={POSH_Policy}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Process_for_Vendor_Due_Diligence_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Process for Vendor Due Diligence</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Process_for_Vendor_Due_Diligence}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Suppliers_Code_of_Conduct_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Suppliers Code of Conduct</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Suppliers_Code_of_Conduct}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Sustainability_Performance_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Integration of Sustainability Performance Objectives 
                      </p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Sustainability_Performance}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={MGT_7_2020_2021_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>MGT_7_2020_2021</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={MGT_7_2020_2021}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={Business_Ethics_Compliance_Procedures_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Business Ethics Compliance Procedures</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={Business_Ethics_Compliance_Procedures}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={MGT_7_2021_2022_img} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>MGT_7_2021_2022</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={MGT_7_2021_2022}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-xl-4 mb-3">
              <div className="bi_shadow_box bg-white p-3">
                <img src={bi1} className="w-100" alt="" />
                <div className="row mt-3">
                  <div className="col-9 col-sm-9 col-md-9 col-xl-9">
                    <p>Code of Conduct Policy</p>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-xl-3">
                    <a
                      className="pdf_link"
                      href={AyanaCodeofConduct}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <PictureAsPdfIcon />
                    </a>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Governance;
