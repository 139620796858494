import React, { useState ,useEffect}  from "react";
import { LinkedInEmbed } from "react-social-media-embed";
import "./linked-in.scss";
import config from "../../config";

const LinkedInFeed = () => {

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [linkedInData, setLinkedInData] = useState([]);

  useEffect(() => {
    fetch(`${config.base_url}linked-in-post`) 
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setIsLoaded(true);
          setLinkedInData(result.linkedin_post);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  const Iframeheight = {
    height: '300px' // Specify the desired height here
  };
  return (
    <>
      <div className="container">
        <div className="row g-3">
          {/* <div className="col-md-4">
          <LinkedInEmbed
              url={linkedInData.linkedin_post_1}
            />
          </div>
          <div className="col-md-4">
          <LinkedInEmbed
              url={linkedInData.linkedin_post_2}
            />
          </div>
          <div className="col-md-4">
          <LinkedInEmbed
              url={linkedInData.linkedin_post_3}
            />
          </div> */}
          <iframe className="iframe-height"  src='https://widgets.sociablekit.com/linkedin-page-posts/iframe/25445929' frameborder='0' width='100%' ></iframe>
        </div>
      </div>
    </>
  );
};

export default LinkedInFeed;
