import React from "react";
import { Link } from "react-router-dom";
const Dashboard = () => {
  return (
    <div className="container">
      <h1 className=" mt-5 mb-5 ">Welcome to Ayana Power dashboard</h1>
      <div className="row col-12 gap-4">
        <Link to={'/admin/enquiry'} className="dashboard_cards col-lg-3 col-6">Total Enquiry</Link>
        <Link className="dashboard_cards col-lg-3 col-6">Total aspirants</Link>
        <Link className="dashboard_cards col-lg-3 col-6">Total Vendors</Link>
        <Link className="dashboard_cards col-lg-3 col-6">Total blogs</Link>
      </div>
    </div>
  );
};

export default Dashboard;
