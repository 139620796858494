import './preloader-form.scss'

const PreloaderForm = () => {
  return (
    <div className="preloaderOverlay">
      <div className="preloaderContainer">
        <div />
      </div>
    </div>
  );
};

export default PreloaderForm;
