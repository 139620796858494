import React from 'react'
import Footer from '../../components/Footer/Footer'
import './thankyou.scss'
import Navbar from '../../components/Navbar/Navbar'

const ThankYou = () => {
    return (
        <div>
            <Navbar />
            <div className='thankyou'>
                <h3>Thank You for applying we will get back to you!</h3>
            </div>
            <Footer />
        </div>
    )
}

export default ThankYou
