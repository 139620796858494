import React from "react";
import "./projects_table.scss";
import HybridProject from "../../assets/img/accordian_operation/hybrid-project.png";
import HybridProject2 from "../../assets/img/accordian_operation/hybrid-project-2.png";
import projects_img from "../../assets/img/projects_img.png";

export const ProjectsAccordian = () => {
  return (
    <div>
      <section>
        <div className="container">
          <div className="row text-center pos_relative">
            <center>
              <div className="title pt-4">
                <h4>Projects</h4>
              </div>
            </center>
            {/* <img src={projects_img} alt="" className="img-fluid projects_img" /> */}
          </div>
          <div className="table-responsive">
            <table className="table table-striped ">
              <thead>
                <tr className="tableRow">
                  <th>S.No</th>
                  <th>Project Image</th>
                  <th>Project Type</th>
                  <th>Location</th>
                  <th>Capacity (MW AC)</th>
                  <th>Project Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <th>
                    <img src={HybridProject} alt="" />
                  </th>
                  <th>Hybrid</th>
                  <td>Pavagada, Karnataka</td>
                  <td>450</td>
                  <td>Under Development</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProjectsAccordian;
