import React from "react";
import "./introduction.scss";
import titleImgWhite from "../../assets/img/title_img_white.svg";
import ceo from "../../assets/img/ceo_img.jpg";
import vision_mission_img from "../../assets/img/vision_mission_img.png";
import investor_img from "../../assets/img/investor_img.png";
import bii from "../../assets/img/investors/bii.png";
import eversource from "../../assets/img/investors/eversource.png";
import niif from "../../assets/img/investors/niif.png";

import { Link } from "react-router-dom";
import AboutCarosel from "../../components/AboutSliderCarosel/AboutCarosel";
import Footer from "../../components/Footer/Footer";
import Leadership from "../../components/LeaderShip/Leadership";
import DirectorsBoard from "../../components/DirectorsBoard/DirectorsBoard";
import { Timeline } from "../../components/NewTimeLine/TimelineItem";
import Navbar from "../../components/Navbar/Navbar";
import about_banner from "../../assets/img/about-banner.jpg";

const Introduction = () => {
  return (
    <div>
      <Navbar />
      <section
        className="pt-0 pb-0 position-relative banner_image_overlay"
        id="about"
      >
        <img src={about_banner} alt="" className="img-fluid w-100" />
        <div className="breadcrumb_text">
          <h4 className="breadcrumb_title">About Us</h4>
          <p className="breadcrumb_link">
            <Link to="/"> Home</Link> | About Us
          </p>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-md-12">
              <div>
                {/* <div className="row introductionContainer">
                  <div className="col-md-3">
                    <div
                      className="title pt-4"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <h4>{""}</h4>
                    </div>
                  </div>
                </div> */}
                <p>
                  We are a utility-scale renewable energy platform dedicated to
                  expanding India’s renewable energy footprint, helping it
                  achieve its 500 GW renewable energy target by 2030. Backed by
                  some of the most significant investment funds - National
                  Investment & Infrastructure Fund, British International
                  Investment, and Green Growth Equity Fund, we support a
                  low-carbon future while helping create jobs in the power
                  sector. We are powered by a team of experts, with vast
                  experience in the industry. Our wide range of renewable energy
                  resources such as solar, wind, round-the-clock, and green
                  hydrogen grants us a solid industry position. We strive
                  towards ensuring a greener and brighter future through
                  innovation and the empowerment of local communities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light" id={"ceo"}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 text-center">
              <img src={ceo} alt="" className="img-fluid" />
            </div>
            <div className="col-md-8">
              <div className="ceo_right_bottom">
                <div className="title">
                  <h4>
                    CEO'<span className="text-lowercase">s</span> Message
                  </h4>
                </div>
                <p className="ceo_name">Mr. Shivanand Nimbargi</p>
                <blockquote className="">
                  India is on the threshold of a green energy revolution. We are
                  striving to improve the renewable energy landscape to help
                  achieve the net zero target. With innovation and integrity at
                  our core, we at Ayana, are committed to empowering communities
                  and powering a better future for generations.
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id={"purpose-vision"} className="px-4">
        <div className="container">
          <div className="row">
            <div className="title text-center">
              <h4 className="m-auto py-3">Vision &amp; Mission</h4>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-5 text-center">
              <img src={vision_mission_img} alt="" className="img-fluid w-50" />
            </div>
            <div className="col-md-7 vision_mission_block ps-5 ps-md-4">
              <h5>Our Vision</h5>
              <p>
                Ayana's strategic vision is to create significant renewable
                power generating capacity across India, complementing it with an
                agenda which reaches out to communities near locations where
                such capacity will be created.
              </p>
              <h5 className="mt-3">Our Mission</h5>
              <p>
                Ayana aims to add 2 GW of renewable power in India every year,
                which includes a combination of wind, solar, hybrid, and RTC
                projects. Throughout this process, we will work closely with
                communities to help them develop, and maintain excellence,
                respect, and integrity in all aspects of our operations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="history bg_grey " id={"milestone"}>
        <div className="container">
          <div className="row">
            <div className="title text-center ">
              <center>
                <h4>milestones</h4>
              </center>
            </div>
          </div>
          <Timeline />
        </div>
      </section>

      <section className="investors pos_relative" id={"investors"}>
        <div className="container">
          <div className="row ">
            <div className="col-md-12 title">
              <h4 className="m-auto py-3">Investors</h4>
            </div>
          </div>
          <p className="pb-4 text-center">
            Investment is the lifeblood of the renewable energy sector. A strong
            collaborative effort is needed to direct capital across various
            verticals such as finances, conversion, transport and consumption of
            energy resources. Decarbonizing the economy is our main goal, and
            can only be achieved through expertise.
          </p>
        </div>
        <img src={investor_img} alt="" className="img-fluid investor_img" />
        <div className="container px-0 px-md-3">
          <div className="row justify-content-center g-3">
            <div className="col-md-4 text-center">
              <div className="flip-card px-3 px-md-0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className=" investor_button_container ">
                      {/* <a
                      href="https://www.niifindia.in/"
                      target="_blank"
                      rel="noreferrer"
                    > */}
                      <div className="investor_button">
                        <div className="p-2">
                          <img src={niif} className="" alt="" />
                        </div>
                        National Investment &amp; Infrastructure Fund
                        <br />
                        <span>(NIIF)</span>
                      </div>
                      {/* </a> */}
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="member_details">
                      <strong>National Investment & Infrastructure Fund</strong>
                      <p className="qulificationsBullets">
                        National Investment and Infrastructure Fund Limited
                        (NIIFL) is a collaborative investment platform for
                        international and Indian investors, anchored by the
                        Government of India.  NIIF Limited manages over USD 4.5
                        billion of equity capital commitments across its three
                        funds – Master Fund, Fund of Funds and Strategic
                        Opportunities Fund, each with its distinct investment
                        strategy. The Indian Govt has a 49% stake in NIIF, with
                        the rest held by marquee foreign and domestic investors
                        such as Abu Dhabi Investment Authority, CPPIB, Temasek,
                        DFC, Australian Super, Ontario Teachers’ Pension Plan,
                        HDFC Group, ICICI bank, Kotak Mahindra Life and Axis
                        Bank. NIIFL invests across asset classes such as
                        infrastructure, private equity and other diversified
                        sectors in India, with the objective to generate
                        attractive risk-adjusted returns for its investors.
                      </p>
                    </div>
                    <div>
                      <a
                        className="text-white bg_blue p-1 rounded-3"
                        href="https://www.niifindia.in/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 text-center">
              <div className="flip-card px-3 px-md-0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    {/* <img src={shivanand} alt="" /> */}
                    <div className="investor_button_container ">
                      {/* <a
                      href="https://www.bii.co.uk/en/"
                      target="_blank"
                      rel="noreferrer"
                    > */}
                      <div className="investor_button ">
                        <div className="p-2">
                          <img src={bii} className="" alt="" />
                        </div>
                        British International Investment
                        <br />
                        <span>(BII, formerly CDC Group)</span>
                      </div>
                      {/* </a> */}
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="member_details">
                      <strong>British International Investment (CDC) </strong>
                      <p className="qulificationsBullets">
                        Wholly owned by the UK government, the BII group is a
                        major global Development Finance Institution (DFI) and
                        impact investor. It has invested over USD 5.5 bn since
                        2008 across 1,300+ investments across emerging economies
                        (primarily Africa and South Asia). BII has strong energy
                        sector and climate focused investing experience. DFI has
                        a strong track record of developing businesses,
                        especially within the clean energy industry – key equity
                        investments include Globeleq (1.3 GW installed capacity
                        across 5 African countries) and AKFED-CDC power platform
                        (325 MW capacity in Uganda and Kenya).
                      </p>
                    </div>
                    <div>
                      <a
                        className="text-white bg_blue p-1 rounded-3"
                        href="https://www.bii.co.uk/en/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 text-center">
              <div className="flip-card px-3 px-md-0">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <div className="investor_button_container ">
                      {/* <a
                      href="https://www.eversourcecapital.com/about/"
                      target="_blank"
                      rel="noreferrer"
                    > */}
                      <div className="investor_button">
                        <div className="p-2">
                          <img src={eversource} className="" alt="" />
                        </div>
                        Green Growth Equity Fund
                        <br />
                        <span>(GGEF)</span>
                      </div>
                      {/* </a> */}
                    </div>
                  </div>
                  <div className="flip-card-back">
                    <div className="member_details">
                      <strong>Green Growth Equity Fund </strong>
                      <p className="qulificationsBullets">
                        JV between Everstone Group and Lightsource bp  
                        Eversource Capital is an equal joint venture between
                        Everstone Group, one of Asia’s premier investment
                        manager with assets in excess of US$ 7 bn across private
                        equity, sustainability and climate impact, logistics,
                        digital and venture capital; and Lightsource bp, a
                        global leader in development and management of solar
                        energy projects.   Eversource manages India&#39;s
                        largest climate impact fund, US$ 741 mn, with anchor
                        investments from India&#39;s National Investment &amp;
                        Infrastructure Fund and UK Government’s Foreign,
                        Commonwealth &amp; Development Office (FCDO).
                      </p>
                    </div>
                    <div>
                      <a
                        className="text-white bg_blue p-1 rounded-3"
                        href="https://www.eversourcecapital.com/about/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Investors /> */}
      </section>

      <section
        className="director_board bg_grey container"
        id="directors-board"
      >
        <div className="title text-center">
          <h4 className="m-auto py-3">Board of directors</h4>

          <div className="row justify-content-center">
            <div className="col-md-8">
              <p className="paraText">
                Ayana is guided by exceptional vision. The leadership’s
                knowledge and expertise ensure a notable growth trajectory for
                us.
              </p>
            </div>
          </div>
        </div>
        <div className="row" id="directors-board">
          {/* <DirectorSlider /> */}
          <DirectorsBoard />
        </div>
      </section>
      <section id={"leadership"} className="bg_grey">
        <div className="container">
          <div className="row">
            <div className="title text-center">
              <h4 className="m-auto py-3">Ayana's Leadership</h4>
              <div className="row justify-content-center">
                <div className="col-md-8">
                  <p className="paraText">
                    Our leaders show the path towards adding 2 GW of renewable
                    energy power in India every year to create significant
                    renewable power generation and become one of the top 3
                    national Renewable Energy Companies.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <>
            <section id="leaders">
              <Leadership />
            </section>
          </>
        </div>
      </section>

      <section className="bg_blue" id={"awards"}>
        <div className="text-center">
          <img src={titleImgWhite} alt="" />
          <h4 className="recognitionTextHeading">Recognition that Matters</h4>
        </div>
        <div
          className="container"
          style={{ paddingLeft: "9px", paddingRight: "13px" }}
        >
          {/* <AboutSliderCarosel /> */}
          <AboutCarosel />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Introduction;
