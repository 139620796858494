import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";

import QHSE from "../../assets/pdf/QHSE.pdf";
import Logo from "../../assets/header-logo.svg";
import "./navigation.scss";

import { useScrollPosition } from "../../hooks/ScrollPosition";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Navigation = () => {
  const scrollPosition = useScrollPosition();
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  // header__middle

  return (
    <header
      className={classNames(
        scrollPosition > 0
          ? "shadow bg-[#434343]"
          : "shadow-none bg-transparent",
        "header__middle"
      )}
    >
      <div className="containerNav2">
        <div className="row">
          {/* Add Logo  */}
          <div className="header__middle__logo">
            <NavLink exact activeclassname="is-active" to="/">
              <img src={Logo} alt="logo" />
            </NavLink>
          </div>

          <div className="header__middle__menus">
            <nav className="main-nav ">
              {/* Responsive Menu Button */}
              {isResponsiveclose === true ? (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    {" "}
                    <FiXCircle />{" "}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="menubar__button"
                    style={{ display: "none" }}
                    onClick={toggleClass}
                  >
                    {" "}
                    <FiAlignRight />{" "}
                  </span>
                </>
              )}

              <ul className={boxClass.join(" ")}>
                <li className="menu-item">
                  <NavLink
                    exact
                    activeclassname="is-active"
                    onClick={toggleClass}
                    to={`/`}
                  >
                    {" "}
                    Home{" "}
                  </NavLink>
                </li>
                <li
                  onClick={toggleSubmenu}
                  className="menu-item sub__menus__arrows"
                >
                  <Link to="#">
                    About <FiChevronDown />
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#`}
                      >
                        About
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#ceo`}
                      >
                        CEO<span className="text-lowercase">s</span> Message
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#purpose-vision`}
                      >
                        Vision & Mission
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#milestone`}
                      >
                        Milestones
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#investors`}
                      >
                        Investors
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#directors-board`}
                      >
                        Board
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#leadership`}
                      >
                        Leadership
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/about#awards`}
                      >
                        Awards & Recognitions
                      </NavHashLink>
                    </li>
                  </ul>
                </li>
                {/* <li className="menu-item ">
                  <NavHashLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/offerings#`}
                  >
                    {" "}
                    Offerings & Projects{" "}
                  </NavHashLink>{" "}
                </li> */}
                <li
                  onClick={toggleSubmenu}
                  className="menu-item sub__menus__arrows"
                >
                  {" "}
                  <Link to="#">
                    {" "}
                    Offerings & Projects
                    <FiChevronDown />{" "}
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      {" "}
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/offerings#`}
                      >
                        {" "}
                        Offerings{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/offerings#projects`}
                      >
                        {" "}
                        Projects{" "}
                      </NavHashLink>{" "}
                    </li>
                  </ul>
                </li>
                <li
                  onClick={toggleSubmenu}
                  className="menu-item sub__menus__arrows"
                >
                  {" "}
                  <Link to="#">
                    {" "}
                    Sustainability <FiChevronDown />{" "}
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/sustainability#`}
                      >
                        {" "}
                        Sustainability{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      {" "}
                      <a
                        onClick={toggleClass}
                        activeclassname="is-active"
                        href={QHSE}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        QHSE{" "}
                      </a>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/governance#`}
                      >
                        {" "}
                        Corporate Goverance{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/csr-initiative#`}
                      >
                        {" "}
                        Community Development{" "}
                      </NavHashLink>{" "}
                    </li>
                  </ul>
                </li>
                <li
                  onClick={toggleSubmenu}
                  className="menu-item sub__menus__arrows"
                >
                  {" "}
                  <NavLink to="/media">
                    {" "}
                    Media <FiChevronDown />{" "}
                  </NavLink>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      {" "}
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/media#`}
                      >
                        {" "}
                        Latest News{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/media#coverage`}
                      >
                        {" "}
                        Coverage{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/media#branding-assets`}
                      >
                        {" "}
                        Branded Assets{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/blogs#`}
                      >
                        {" "}
                        Blog{" "}
                      </NavHashLink>{" "}
                    </li>
                  </ul>
                </li>
                <li
                  onClick={toggleSubmenu}
                  className="menu-item sub__menus__arrows"
                >
                  {" "}
                  <Link to="#">
                    {" "}
                    Careers <FiChevronDown />{" "}
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      {" "}
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/careers#`}
                      >
                        {" "}
                        Work with us{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/ayana-life#`}
                      >
                        {" "}
                        Life@Ayana{" "}
                      </NavHashLink>{" "}
                    </li>
                  </ul>
                </li>
                <li
                  onClick={toggleSubmenu}
                  className="menu-item sub__menus__arrows"
                >
                  {" "}
                  <Link to="#">
                    {" "}
                    Partnership <FiChevronDown />{" "}
                  </Link>
                  <ul className={boxClassSubMenu.join(" ")}>
                    <li>
                      {" "}
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/partners#`}
                      >
                        {" "}
                        Partners{" "}
                      </NavHashLink>{" "}
                    </li>
                    <li>
                      <NavHashLink
                        onClick={toggleClass}
                        activeclassname="is-active"
                        to={`/suppliers#`}
                      >
                        {" "}
                        Suppliers{" "}
                      </NavHashLink>{" "}
                    </li>
                  </ul>
                </li>
                <li className="menu-item ">
                  <NavHashLink
                    onClick={toggleClass}
                    activeclassname="is-active"
                    to={`/contact#`}
                  >
                    {" "}
                    Contact{" "}
                  </NavHashLink>{" "}
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navigation;
