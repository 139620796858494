import React, { useEffect, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import moment from "moment";
import Spinner from "../Spinner/Spinner";
import "./news-api.scss";

const NewsApi = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const uploadUrl = `https://backend.ayanapower.com/storage/media/`;
  useEffect(() => {
    fetch("https://backend.ayanapower.com/api/get-media")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Spinner />
      </div>
    );
  } else {
    return (
      <>
        <div className="row g-3">
          {items.data.map((media) => (
            <div className="col-12 col-sm-6 col-md-6 col-xl-6">
              <div className="media_shadow_box_news bg-white ">
                <div key={media.id}>
                  <p className="mb-3">{media.description}</p>
                  <a
                    className="blue_text pdf_link"
                    href={`${uploadUrl}${media.file}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <PictureAsPdfIcon /> View PDF
                  </a>
                  <p className="mt-3">
                    Posted: {moment(media.created_at).format("DD MMM, YYYY")}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
};

export default NewsApi;
