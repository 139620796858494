import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import CoAwards01 from "../../assets/img/AboutSliderImages/CoAwards01.jpg";
import Ayana_Renewable_Engg_team_Award from "../../assets/img/AboutSliderImages/Ayana_Renewable_Engg_team_Award.jpg";
import Ayana_Renewable_MA_Award from "../../assets/img/AboutSliderImages/Ayana_Renewable_MA_Award.jpg";
import Ayana_Renewable_Saravanan_Award from "../../assets/img/AboutSliderImages/Ayana_Renewable_Saravanan_Award.jpg";
import CoAwards07 from "../../assets/img/AboutSliderImages/CoAwards07.jpg";
// import Best_Employer_Brand_Award from "../../assets/img/AboutSliderImages/Best_Employer_Brand_Award.jpg";
import CoAwards08 from "../../assets/img/AboutSliderImages/CoAwards08.jpg";
import CoAwards10 from "../../assets/img/AboutSliderImages/CoAwards10.jpg";
import CoAwards11 from "../../assets/img/AboutSliderImages/coaward11.JPG";
import iip from "../../assets/img/AboutSliderImages/iip.png";
import iip1 from "../../assets/img/AboutSliderImages/iip1.png";
import ArrowLeft from "../../assets/left-arrow1.svg";
import ArrowRight from "../../assets/right-arrow1.svg";
import Ayana_Power from "../../assets/awards/Ayana_Power.JPG";
import image13 from "../../assets/awards/image13.JPG";
import image18 from "../../assets/awards/image18.jpg";

import image19 from "../../assets/awards/image19.jpeg";

import image12 from "../../assets/awards/images12.png";

import National from "../../assets/awards/National.png";
import British_Saftey_Council from "../../assets/awards/British_Safety_Council.png";

const responsive = {
  xldesktop: {
    breakpoint: { max: 2526, min: 1920 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 1536, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const images = [
  British_Saftey_Council,
  National,
  Ayana_Power,
  image13,
  image18,
  image12,
  image19,
  iip,
  iip1,
  CoAwards01,
  Ayana_Renewable_MA_Award,
  Ayana_Renewable_Engg_team_Award,
  Ayana_Renewable_Saravanan_Award,
  CoAwards08,
  CoAwards11,
  CoAwards10
];

const AboutCarosel = () => {
  return (
    <section className="">
      <div className="container">
        <Carousel
          autoPlay={true}
          infinite
          responsive={responsive}
          itemClass="p-2"
        >
          {images.map(item => {
            return (
              <div className="slider_image">
                <img
                  className="img-fluid w-100 h-100"
                  src={item}
                  alt="arrow"
                />
              </div>
            );
          })}
        </Carousel>
      </div>
    </section>
  );
};

export default AboutCarosel;
