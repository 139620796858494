import axios from "axios";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import parse from "html-react-parser";
import "./leadership.scss";
import LeadershipCarosel from "../../components/LeadershipCarosel/LeadershipCarosel";
import Navbar from "../../components/Navbar/Navbar";
import blog_banner from "../../assets/img/blogs/blog_banner.jpg";
import leadership_banner from "../../assets/img/leadership-banner.png";

const LeaderShip = () => {
  const { id } = useParams();
  const [member, setMember] = useState([]);

  const uploadUrl = "https://backend.ayanapower.com/storage/team-member/";

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await axios.get(
          `https://backend.ayanapower.com/api/get-member/${id}`
        );
        setMember(data.member);
        console.log(data.member, "hello");
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, [id]);
  return (
    <>
      <Navbar />
      <div id="blog-detail">
        <div className="blogDetailsContainer">
          <section
            className="pt-0 pb-0 position-relative banner_image_overlay"
            id={"blog"}
          >
            <img src={leadership_banner} alt="" className="img-fluid w-100" />
            <div className="breadcrumb_text">
              <h4 className="breadcrumb_title">Leaders</h4>
              <p className="breadcrumb_link">
                <Link to="/"> Home</Link> | Leaders
              </p>
            </div>
          </section>

          <div class="leadershipDetailscontainer ">
            <div class="row">
              <div class="col-md-3">
                <img
                  className="mt-3 img-fluid w-100 h-auto object-cover mx-4"
                  src={`${uploadUrl}${member.image}`}
                  alt="..."
                />
                {/* <div className="backButtonLeadership">
                                    <button className="ml-3 " onClick={() => navigate("/about#leaders")}>Go back</button>
                                </div> */}
              </div>
              <div class="col-md-9">
                {member && (
                  <section
                    style={{ paddingLeft: "50px", paddingRight: "50px" }}
                  >
                    <h2>{member.name}</h2>
                    <h5>{member.designation && parse(member.designation)}</h5>
                    <p className="aboutLeaderDesc">
                      {member.about_member && parse(member.about_member)}
                    </p>
                  </section>
                )}
              </div>
            </div>
          </div>
          <LeadershipCarosel />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LeaderShip;
