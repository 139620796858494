/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/AdminLayout";
import { isAuth, logout } from "../../network/helper";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/BreadCrumbs";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth()) {
      logout();
    } else {
      setLoading(false);
      navigate("/admin/dashboard");
    }
  }, []);

  return (
    <div>
      {!loading && (
        <AdminLayout>
          <Breadcrumbs />
          <Outlet />
        </AdminLayout>
      )}

      {loading && (
        <div className="d-flex align-items-center justify-content-center">
          loading...{" "}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
